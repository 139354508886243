import React, { useEffect, useState } from "react";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import Image from 'react-bootstrap/Image';
import { useLocation } from "react-router-dom";
import { isEmpty, isPageQuickAccess } from "../../../../helpers/Functions";
import { connect } from "react-redux";
import { quickSubmitElementResponse, quickTextElementToEmail, submitStudentElementResponse, textElementToEmail } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";

import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import BaseTextField from "../../BaseTextField";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import BaseCheckbox from "../../BaseCheckbox";
import ActionIconButton from "../../ActionIconButton";
import { isNotStudent } from "../../../../helpers/Utils";
import BaseButton from "../../BaseButton";
import { DEFAULT_EMAIL_FOOTER } from "../../../../models/Constants";
import { marked } from "marked";

const CustomPlanContent = ({ ...props }) => {
	const [expIndex, setExpIndex] = useState(0);
	const [selectedItem, setSelectedItem] = useState(null)

	useEffect(() => {
		setSelectedItem(props.element?.context?.custom_plan_items[expIndex])
	}, [expIndex])

	return(<>
		{
			(!isEmpty(props.results)) ? (<>
				<TextView className="mb-4">
					<div dangerouslySetInnerHTML={{ __html: props.results?.replace(/<\/p><br><p>/g, "<\/p><p>") }} />
					{
						(!isNotStudent(props?.userCapabilities?.role) || isEmpty(props?.userCapabilities)) && (
							<div className="d-flex justify-content-center align-items-center mt-4">
								<BaseButton
									variant="text"
									onClick={() => {props.emailTextToUser()}}
								> 
									Email This To Me 
								</BaseButton>
							</div>
						)
					}
				</TextView>
			</>) : (<>
				{
					(isNotStudent(props?.userCapabilities?.role) && !isEmpty(props?.userCapabilities)) && (<>
						<TextView className="d-block font-size-medium color-primary my-2 ms-1">
							Main Prompt
						</TextView>
						<div className="mb-2 p-1">
							<div dangerouslySetInnerHTML={{__html: marked(props.element?.description)}} />
						</div>
						<TextView className="d-block font-size-medium color-primary my-2 ms-1">
							Questions
						</TextView>
					</>)

				}
				
				<div className="quiz-container">
					<div className="w-100">
					{
						!isEmpty(props.element?.context?.custom_plan_items) ? (<>
							<div className="mb-auto">
								<div className="d-block mb-2">
									<TextView className="d-block font-size-medium color-black mb-2">
										{selectedItem?.description} {isNotStudent(props?.currentCapabilities) && `(${selectedItem?.title})`}
									</TextView>
									{
										(selectedItem?.type == "input") ? (
											<div className="w-100">
												<BaseTextField
													customclasses="mb-3 mt-2"
													placeholder="Give a proper answer for more accurate results"
													value={props.answer[expIndex]?.value}
													multiline
													rows={3}
													onChange={(e) => {
														let newArr = [...props.answer];
		
														newArr[expIndex] = {
															...newArr[expIndex],
															value: e.target.value
														};
		
														props.setAnswer(newArr);
		
													}}
												/>
											</div>
										) : (selectedItem?.type == "single") ? (
											<div className="px-5">
												<RadioGroup
													name="radio-buttons-group"
													onChange={(e) => {
														let newArr = [...props.answer];
		
														newArr[expIndex] = {
															...newArr[expIndex],
															value: e.target.value
														};
		
														props.setAnswer(newArr);
													}}
												>
													{
														selectedItem.choices?.map((choice, i) => {
															return (
																<FormControlLabel
																	key={ i }
																	value={ choice.label }
																	control={ <Radio /> }
																	label={ choice.label }
																/>
															)
														})
													}
												</RadioGroup>
											</div>
										) : (selectedItem?.type == "multi") ? (
											selectedItem?.choices?.map((item, i) => {
												return (
													<div className="d-block ps-5">
														<BaseCheckbox 
															key={i}
															customclasses="quiz-selection mx-1 font-size-medium"
															className="d-block p-0 mx-1 my-2"
															title={item.label}
															onChange={(e) => {
																if(e.target.checked) {
																	let newArr = [...props.answer];
																	let newAnswer = [ ...newArr[expIndex].value ];
		
																	newAnswer.push(item.label)
																	newArr[expIndex].value = newAnswer
																	props.setAnswer(newArr)
																}
																else {
																	if(!isEmpty(props.answer)) {
																		let newArr = [...props.answer];
																		let newAnswer = [ ...newArr[expIndex].value ];
																		
																		newAnswer = newAnswer?.filter((str) => str !== item.label);
																		newArr[expIndex].value = newAnswer
																		props.setAnswer(newArr)
																	}
																}
															}}
														/><br/>
													</div>
												)
											})
										) : null
									}
								</div>
								<div className="mt-auto w-100">
									{
										(props.submitting) ? (
											<div className="d-flex justify-content-center">
												<TextView className="font-size-large color-primary">Hold on. We’re personalizing it for you.</TextView>
											</div>
										) : (
											<div className="d-flex">
												{expIndex > 0 && (
													<ActionIconButton 
														className="font-size-large color-primary me-auto"
															style={{ marginTop: '0.75rem' }}
														label="Previous"
														onClick={() => {
															setExpIndex(expIndex - 1)
														}}
													/>
												)}
												{expIndex < props.element?.context?.custom_plan_items.length - 1 && (
													<ActionIconButton 
														className="font-size-large color-primary ms-auto"
															style={{ marginTop: '0.75rem' }}
														label="Next"
														onClick={() => {
															if(isEmpty(props.answer[expIndex].value)){
																displayToast("error", "Please answer to continue")
																return
															}
				
															setExpIndex(expIndex + 1)
														}}
													/>
												)}
												{expIndex == props.element?.context?.custom_plan_items.length - 1 && (
													<ActionIconButton 
														className="font-size-large color-primary ms-auto"
															style={{ marginTop: '0.75rem' }}
														label="Submit"
														onClick={() => {
															if(isEmpty(props.answer[expIndex].value)){
																displayToast("error", "Please answer to submit")
																return
															}
				
															props.submitStudentResponse()
														}}
													/>
												)}
											</div>
										)
									}
								</div>
							</div>
						</>) : (
							<TextView>No Comments Found.</TextView>
						)
					}
					</div>
				</div>
			</>)
		}
		
	</>)
}

const CustomPlanElement = ({ ...props }) => {
	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);
	const [answer, setAnswer] = useState([])
	const [results, setResults] = useState(null)
	const [submitting, setSubmitting] = useState(null)

	useEffect(() => {
		let newAnswersArr = []

		props.element?.context?.custom_plan_items.map(item => {
			newAnswersArr = [
				...newAnswersArr, {
					"token" : item.token,
					"type" : item.type,
					"value" : item.type == "multi" ? [] : ""
				}
			]
		})

		setAnswer(newAnswersArr)

		if(!isNotStudent(props.currentCapabilities.role)){
			if(!isEmpty(props.element.attachments[0].student_attachment_response)){
				const studentResponse = props.element.attachments[0].student_attachment_response
				setResults(studentResponse.content)
			}
		}
	}, [props.element])

	const submitStudentResponse = () => {
		if(
			props.element.context?.survey_type == "multi" && 
			answer.length < parseInt(props.element.context.choice_limit) && 
			(!isNotStudent(props.currentCapabilities.role) || isEmpty(props.currentCapabilities.role))
		){
			displayToast("error", `Please select minimum of ${parseInt(props.element.context.choice_limit)} items before you submit`)
			return
		}

		const student_response = {
			main_prompt : props.element.description,
			answers: answer
		}

		let params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element?.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props?.element?.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: student_response
		}

		setSubmitting(true)
		if(isEmpty(props.currentCapabilities.role) && !isEmpty(props.quickAccess)){
			params.email = props.email;
			params.firstname = props.name.firstname;
			params.lastname = props.name.lastname;

			quickSubmitElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					const gptResponse = response.data.data[0].content;
					setResults(gptResponse)
				}

				setSubmitting(false)
			})
		}
		else if(!isNotStudent(props.currentCapabilities.role)) {
			submitStudentElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					const gptResponse = response.data.data.gpt_response;
					setResults(gptResponse)
				}

				setSubmitting(false)
			})
		}
		else {
			//admin submit
			params.is_admin = true;

			submitStudentElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					const gptResponse = response.data.data[0].content;
					setResults(gptResponse)
				}

				setSubmitting(false)
			})
		}
	}

	const emailTextToUser = () => {
		let params = {
			name : props.name,
			text: results,
			email: props.email,
			title : props.element?.title,
			footer: isEmpty(props.element?.context?.ai_email_footer) ? DEFAULT_EMAIL_FOOTER : props.element.context.ai_email_footer,
		}

		if(props?.quickAccess){
			quickTextElementToEmail(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Email has been sent.  Please check your email.");
				}
			});
		}
		else {
			textElementToEmail(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Email has been sent please check your email");
				}
			});
		}
	}

	return (
		(!isQuickAccess) ? (
			<LessonAccordion {...props}>
				<CustomPlanContent 
					element={props.element}
					userCapabilities={props.currentCapabilities}
					updateProgress={props.updateProgress}
					answer={answer}
					setAnswer={setAnswer}
					submitStudentResponse={submitStudentResponse}
					submitting={submitting}
					results={results}
					emailTextToUser={emailTextToUser}
				/>
				{
					(isNotStudent(props?.currentCapabilities?.role) && !isEmpty(props?.currentCapabilities) && !isEmpty(results)) && (
						<div className="d-flex justify-content-center align-items-center">
								<BaseButton
									className="font-primary font-size-xm bg-primary color-white py-2"
									customstyles={{ borderRadius: '30px', height: '3.5rem', width: '15rem' }}
									onClick={() => {
										setResults(null)
									}}
								> 
									Retry 
								</BaseButton>
						</div>
					)
				}
				{
				(!isNotStudent(props?.currentCapabilities?.role) && props.element.last_element && !isEmpty(results)) && (
					<div className="d-flex ms-2 mt-2">
						<BaseButton 
							customclasses="ms-auto"
							onClick={() => {
								props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, props.studentProgress);
							}}
						>
							Next Element
						</BaseButton>
					</div>
				)
			}
			</LessonAccordion>
		) : (
			<CustomPlanContent 
				element={props.element}
				userCapabilities={props.currentCapabilities}
				answer={answer}
				setAnswer={setAnswer}
				submitStudentResponse={submitStudentResponse}
				submitting={submitting}
				results={results}
				emailTextToUser={emailTextToUser}
			/>
		)
		
	)

	return 
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
	}

	return data;
}

export default connect(mapStateToProps)(CustomPlanElement);