import axios from "axios";
import { objectToUrlQuery } from "../../helpers/Functions";

export function getOwnerLessonDetails(library_party_id, module_resource_id, lesson_resource_id, is_mentor = "0") {
	return axios.get(
		process.env.REACT_APP_API_URL
		+ "/lesson/get-owner-lesson-details/"
		+ lesson_resource_id
		+ `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}&is_mentor=${is_mentor}`
	);
}

export function getOwnerLessonContent(library_party_id, module_resource_id, lesson_resource_id, is_mentor = "0") {
	return axios.get(
		process.env.REACT_APP_API_URL
		+ "/lesson/get-owner-lesson-content/"
		+ lesson_resource_id
		+ `?library_party_id=${library_party_id}&module_resource_id=${module_resource_id}&is_mentor=${is_mentor}`
	);
}

export function getLessonElements(lessonID, params = {}, config = {}) {
	return axios.get(process.env.REACT_APP_API_URL + "/lesson/get-lesson-elements/" + lessonID + "?" + objectToUrlQuery(params), config);
}

export function updateLesson(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/lesson/create-owner-module-lesson", params);
}

export function sortLessons(params,lesson_id) {
	return axios.put(process.env.REACT_APP_API_URL + "/lesson/sort-owner-lesson/" + lesson_id, params);
}

export function deleteLesson(params, lesson_id) {
	return axios.delete(process.env.REACT_APP_API_URL + "/lesson/delete-owner-lesson/" + lesson_id, {params});
}

export function getStudentLessonContent(params, lesson_id){
	return axios.get(process.env.REACT_APP_API_URL + "/lesson/get-student-lesson-content/" + lesson_id + "?" + objectToUrlQuery(params))
}

export function getStudentLessonActionSteps(lesson_id){
	return axios.get(process.env.REACT_APP_API_URL + `/lesson/${lesson_id}/action-steps`);
}