import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserByToken } from "./crud/person.crud";
import { setUserCapabilities, setCurrentCapabilities, setSuperAdmin } from "./redux/slices/authSlice";
import { setLanguages } from "./redux/slices/dashboardSlice";
import { setAppVersion } from "./redux/slices/appSlice";
import { isEmpty } from "../helpers/Functions";
import SplashScreen from "./SplashScreen";
import { getLanguages } from "./crud/masterdata.crud";
import displayToast from "../theme/partials/DisplayToast";
import { ROLES, ROLE_SUPER_ADMIN, SUPER_PERMISSIONS } from "../models/Constants";

const LoginInit = ({
	isSuperAdmin,
	appVersion,
	setUserCapabilities,
	setCurrentCapabilities,
	setSuperAdmin,
	currentCapabilities,
	setLanguages,
	setAppVersion
}) => {
	const [redirect, setRedirect] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		initializeUserData(
			setUserCapabilities,
			setCurrentCapabilities,
			setSuperAdmin,
			currentCapabilities,
			setLanguages,
			setRedirect,
			true,
			appVersion,
			setAppVersion,
			navigate
		);
	}, []);

	useEffect(() => {
		if(!redirect) {
			return;
		}

		if(isSuperAdmin == 1) {
			navigate('/admin-panel');
		}
		else {
			navigate('/dashboard');
		}
	}, [redirect]);

	return (<SplashScreen />)
}



export function initializeUserData(
	setUserCapabilities,
	setCurrentCapabilities,
	setSuperAdmin,
	currentCapabilities,
	setLanguages,
	callback = undefined,
	isLogin = false,
	appVersion,
	setAppVersion,
	navigate
) {
	if(process.env.REACT_APP_VERSION != appVersion) {
		localStorage.clear()
		setAppVersion(process.env.REACT_APP_VERSION)
		navigate("/login")
		window.location.reload(); 

		return false
	}

	getUserByToken().then((response) => {
		let superadmin = 0;
		let role;

		setUserCapabilities(response.data.data[0].companies);

		if(!isEmpty(response.data.data[0].is_super_admin)) {
			superadmin = response.data.data[0].is_super_admin;
			setSuperAdmin(superadmin);
		}
		else {
			setSuperAdmin(superadmin);
		}

		let capabilities = {};

		if(isLogin || isEmpty(currentCapabilities)) {
			capabilities = {
				company: response.data.data[0].companies[0],
				role: superadmin ? ROLE_SUPER_ADMIN : response.data.data[0]?.companies[0]?.roles[0].role_id,
				role_standard_id: response.data.data[0]?.companies[0]?.roles[0].role_standard_id,
				role_name: superadmin ? ROLES.find(role => role.value === ROLE_SUPER_ADMIN).label : 
					response.data.data[0]?.companies[0]?.roles[0].role_name,
				additional_info: JSON.parse(response.data.data[0].additional_info),
			}
		}
		else {
			capabilities = {
				company: currentCapabilities?.company,
				additional_info: currentCapabilities.additional_info,
				role : currentCapabilities.role,
				role_name: currentCapabilities.role_name,
				is_limited : currentCapabilities.company?.roles.find(role => role.role_id == currentCapabilities.role)?.is_limited || 0,
			}
		}

		setCurrentCapabilities(capabilities);

		if(!isEmpty(callback)) {
			callback(true);
		}
	});

	getLanguages().then((response) => {
		if(response.data.init[0].status === 'error') {
			displayToast("error", "Data initialization error.");
		}
		else {
			let data = [];

			response.data?.data?.map((lang) => {
				data.push({"key": lang.language_key, "name": lang.language_name});
			});

			setLanguages(data);
		}
	});
}

const mapStateToProps = (state) => {
	let data = {
		appVersion: state.app.app_version,
		currentCapabilities: state.auth.current_capabilities,
		isSuperAdmin: state.auth.is_super_admin
	}

	return data;
}

const mapDispatchToProps = {
	setUserCapabilities,
	setCurrentCapabilities,
	setSuperAdmin,
	setLanguages,
	setAppVersion
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginInit);

