import React, { useRef, useState } from "react";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import Image from 'react-bootstrap/Image';
import { useLocation } from "react-router-dom";
import { isPageQuickAccess } from "../../../../helpers/Functions";
import { connect } from "react-redux";
import { submitStudentElementResponse } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import { isNotStudent } from "../../../../helpers/Utils";
import BaseButton from "../../BaseButton";
import KonvaStageCanvas from "../../../../app/dashboard/modals/forms/konva_canvas/KonvaStageCanvas";

const QuotesContent = ({ ...props }) => {
	const submitStudentResponse = () => {
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
		}

		// to enable in the student view
		submitStudentElementResponse(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, response.data.init[0]?.["library_progress"]);
			}
		})
	}

	const stageRef = useRef(null);

	return(<>
		<Image
			className="mt-4 d-block mx-auto w-75 "
			src={ process.env.REACT_APP_S3_BUCKET + props.element?.context.quote_img }
			style={{ maxHeight: '800px', maxWidth: '500px' }}
			fluid
		/>
		{
			(!isNotStudent(props?.userCapabilities?.role) && props.element.last_element) && (
				<div className="d-flex ms-2 mt-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							submitStudentResponse();
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}
	</>)
}

const QuotesElement = ({ ...props }) => {
	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);

	return (
		(!isQuickAccess) ? (
			<LessonAccordion {...props}>
				<QuotesContent 
					element={props.element}
					userCapabilities={props.currentCapabilities}
					updateProgress={props.updateProgress}
				/>
			</LessonAccordion>
		) : (
			<QuotesContent 
				element={props.element}
				userCapabilities={props.currentCapabilities}
			/>
		)
		
	)

	return 
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
	}

	return data;
}

export default connect(mapStateToProps)(QuotesElement);