import axios from "axios";
import { objectToUrlQuery } from "../../helpers/Functions";

export function getStudentActionsteps() {
    return axios.get(process.env.REACT_APP_API_URL + '/element/action-steps');
}

export function createActionstep(data) {
    return axios.post(process.env.REACT_APP_API_URL + '/element/action-steps', data);
}

export function updateActionstep(data) {
    return axios.put(process.env.REACT_APP_API_URL + '/element/action-steps', data);
}

export function deleteActionstep(actionstepId) {
    return axios.delete(process.env.REACT_APP_API_URL + '/element/action-steps', {
        data: {
            action_step_id: actionstepId,
        }
    });
}

export function makeActionstepComment(actionstepId, data) {

}

export function getActionstepComment(actionstepId) {

}

export function editActionstepComment(commentId, data) {

}

export function deleteActionstepComment(actionstepId, data) {

}