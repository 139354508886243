import React from "react";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import { useLocation } from "react-router-dom";
import { isPageQuickAccess } from "../../../../helpers/Functions";
import { isNotStudent } from "../../../../helpers/Utils";
import { connect } from "react-redux";
import BaseButton from "../../BaseButton";
import displayToast from "../../DisplayToast";
import { submitStudentElementResponse } from "../../../../app/crud/element.crud";

const AudioContent = ({...props}) => {
	const submitStudentResponse = () => {
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
		}

		submitStudentElementResponse(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, response.data.init[0]?.["library_progress"]);
			}
		})
	}

	return(<>
		<TextView customclasses="mb-4">
			{props?.element?.description}
		</TextView>

		<audio className="w-75 mx-auto d-block" controls>
			<source
				src={process.env.REACT_APP_S3_BUCKET + props.element?.directory + props?.element?.file_name}
				type="audio/mpeg"
			/>
		</audio>
		{
			(!isNotStudent(props?.userCapabilities?.role) && props.element.last_element) && (
				<div className="d-flex ms-2 mt-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							submitStudentResponse();
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}
	</>)
}

const AudioElement = ({ ...props }) => {
	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);

	return (
		(!isQuickAccess) ? (
			<LessonAccordion {...props}>
				<AudioContent 
					element={props.element}
					userCapabilities={props.currentCapabilities}
					updateProgress={props.updateProgress}
				/>
			</LessonAccordion>
		) : (
			<AudioContent 
				element={props.element} 
				userCapabilities={props.currentCapabilities}
			/>
		)
	)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
	}

	return data;
}

export default connect(mapStateToProps)(AudioElement);