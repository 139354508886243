import React, { createContext, useContext, useEffect, useState } from "react";
import { setAI } from "../../../../app/redux/slices/dashboardSlice";
import { connect } from "react-redux";
import LessonAccordion from "./LessonAccordion";
import TextView, { ElementTitle } from "../../Typography";
import BaseButton from "../../BaseButton";
import BaseRadioButton from "../../BaseRadioButton";
import displayToast from "../../DisplayToast";
import DatePickerButton from "../../DatePickerButton";
import { getModuleActionSteps, getStudentActionResponse } from "../../../../app/crud/module.crud";
import {InputLabel, IconButton} from '@mui/material/';
import { Link } from "react-router-dom";
import BaseTextField from "../../BaseTextField";
import RadioGroup from "@mui/material/RadioGroup";
import { formatDate, isEmpty, safeJsonParse } from "../../../../helpers/Functions";
import Divider from "../../Divider";
import TextFieldIconButton from "../../TextFieldIconButton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { isNotStudent } from "../../../../helpers/Utils";
import { submitStudentElementResponse } from "../../../../app/crud/element.crud";
import { DEADLINE_CUSTOM } from "../../../../models/Constants";
import { ActionstepCard, ActionstepCardEditModal, createDefaultActionData } from "./ActionStepsElement";
import { createActionstep, deleteActionstep, updateActionstep } from "../../../../app/crud/actionstep.crud";
import YesNoDialog from "../../YesNoDialog";

const lessonActionStepContext = createContext();

const LessonActionsContainer = ({ title = "", lessonId, actionstepsElements = [], isAdmin }) => {
	const [customFieldDatas, setCustomFieldDatas] = useState({});

	const { onActionStepEdit, onActionStepAdd } = useContext(lessonActionStepContext);

	return (
		<div className="action-step-summary">

			<div className="d-flex">
				<InputLabel className="font-size-medium font-weight-black color-septenary me-2">
					LESSON: {title}
				</InputLabel>
				{/* 
					Link for Lesson View no API yet
				<Link
					className="font-size-medium"
					onClick={(e) => {
						console.log("")
					}}
				>
					View
				</Link> */}
			</div>

			{actionstepsElements.map((actionElem, i) => (
				(actionElem?.content?.length > 0) && (
					<>
						<Divider className="mb-2"/>
						<InputLabel className="font-size-medium color-septenary">
							<b>Title:</b> {actionElem.title || `Action Element ${i + 1}`}
						</InputLabel>
						{actionElem.content.map((studAns, j) => (
							<ActionstepCard 
								key={studAns?.id || j}
								index={j + 1}
								actionstepData={studAns}
								internalSaveMode={false}
								withStartFinishBtn={false}
								onEdit={(actionData, showModal) => {
									onActionStepEdit(lessonId, actionElem.id, actionData, showModal, true);
								}}
								askForNotes={isAdmin}
								// elementSettings={studAns?.context}
							/>
						))}
						<div className="d-flex justify-content-center p-3 mb-3 align-items-center">
							<TextFieldIconButton
								className="w-100 mb-2"
								placeholder="Add a custom action step"
								multiline
								maxRows="2"
								value={customFieldDatas[actionElem.id] || ""}
								onChange={(e) => {
									let newCustomFieldDatas = {...customFieldDatas};

									newCustomFieldDatas[actionElem.id] = e.target.value;

									setCustomFieldDatas(newCustomFieldDatas);
								}}
								iconbutton={
									<IconButton 
										className="ms-1"
										onClick={() => {
											let value = customFieldDatas[actionElem.id];

											if(isEmpty(value)) {
												return;
											}

											let newCustomFieldDatas = {...customFieldDatas};

											newCustomFieldDatas[actionElem.id] = "";

											setCustomFieldDatas(newCustomFieldDatas);

											onActionStepAdd(lessonId, actionElem.id, value);
										}}
									>
										<AddCircleOutlineIcon className="color-green font-size-xxl"/>
									</IconButton>
								}
							/>
						</div>
					</>
				)
			))}
		</div>
	);
}

const LessonCardSummary = ({
	lessonActionSteps = [],
	onEdit = () => {},
	isAdmin = false,
}) => {
	return(
		<div className="action-step-lesson px-1">
			{lessonActionSteps?.map((lesson) => (<>
				{(lesson?.action_steps?.length > 0 && lesson?.action_steps[0]?.content?.length > 0) && (
					<LessonActionsContainer 
						key={lesson.id}
						lessonId={lesson.id}
						title={lesson?.title}
						actionstepsElements={lesson?.action_steps}
						isAdmin={isAdmin}
					/>
				)}
			</>))}
		</div>
	)
}

const ActionSummaryElement = ({ ...props }) => {
	const [studentResponse, setStudentResponse] = useState([])
	
	const [answerSent, setAnswerSent] = useState(false);
	const [lessonsElementsActionSteps, setLessonsElementsActionSteps] = useState([]);
	const [showEditActionStep, setShowEditActionStepModal] = useState(false);
	const [selectedActionstep, setSelectedActionstep] = useState();
	const [targetLesson, setTargetLesson] = useState();
	const [targetElement, setTargetElement] = useState();
	const [showRemoveActionStepDialog, setShowRemoveActionStepDialog] = useState(false);
	const [studentProgress, setStudentProgress] = useState(0);

	const getActionSteps = () => {
		let lessonResponse = {};
		let actionStepsResponse = {};

		for(let studResp of studentResponse) {

			lessonResponse[studResp?.lesson_id] = true;

			for(let actionResp of studResp?.action_steps) {
				actionStepsResponse[actionResp?.id] = actionResp?.due_date;
			}
		}

		const params = {
			library_party_id : props?.element?.library_party_id,
			module_resource_id : props?.element?.module_resource_id
		}

		if(isNotStudent(props.currentCapabilities.role)) {
			getModuleActionSteps(params).then((response) => {
				if (response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					let newLessonsElementsActionSteps = [];
					let lessons = response.data.data;
	
					for(let lesson of lessons) {
						let newLesson = {
							id: lesson.resource_id,
							title: lesson.title,
							action_steps: [],
						};

						for(let actionElem of lesson.elements) {
							let newActionElem = {
								id: actionElem.element_id,
								title: actionElem.element_title,
								content: actionElem.attachments?.[0]?.attachment_additionals ?? [],
							}
	
							newLesson.action_steps.push(newActionElem);
						}
	
						newLessonsElementsActionSteps.push(newLesson);
					}
					setLessonsElementsActionSteps(newLessonsElementsActionSteps);
				}
			});
		}
		else {
			getStudentActionResponse(params).then((response) => {
				if (response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					let newLessonsElementsActionSteps = [];
					let lessons = response.data.data;
					
					for(let lesson of lessons) {
						let newLesson = {
							id: lesson.lesson?.lesson_id,
							title: lesson.lesson.title,
							action_steps: [],
						};
	
						// student has response already indlude only lessons that are in summary response
						if(answerSent && !lessonResponse[newLesson?.id]) {
							continue;
						}
	
						for(let actionElem of lesson.action_steps) {
							let newActionElem = {
								id: actionElem.element_id,
								title: actionElem.element_title,
								content: actionElem.content?.filter((cont) => !(answerSent && !actionStepsResponse[cont?.id])).map((cont) => ({
									...cont,
									due_date: answerSent ? actionStepsResponse[cont?.id] : cont?.due_date,
								})),
							}
	
							newLesson.action_steps.push(newActionElem);
						}
						newLessonsElementsActionSteps.push(newLesson);
						setLessonsElementsActionSteps(newLessonsElementsActionSteps);
					}
				}
			});
		}

	}
	
	useEffect(() => {
		let studentAttachmentResponse = props.element?.attachments?.[0]?.student_attachment_response;
		let hasStudentResponse = !isEmpty(studentAttachmentResponse);

		if(hasStudentResponse) {
			studentAttachmentResponse.content = safeJsonParse(studentAttachmentResponse?.content);
			studentAttachmentResponse.remarks = safeJsonParse(studentAttachmentResponse?.remarks);
		}

		if(hasStudentResponse) {
			setStudentResponse(studentAttachmentResponse?.content ?? []);
		}
		
		setAnswerSent(hasStudentResponse);
	}, [props.element])

	useEffect(() => {
		getActionSteps();
	}, [studentResponse]);

	const submitStudentResponse = () => {
		let studentAnswers = lessonsElementsActionSteps.map((lesson) => {
			let lessonActions = {
				lesson_id: lesson?.id,
				action_steps: [],
			};

			lesson.action_steps.forEach((elemAction) => {
				lessonActions.action_steps = [
					...lessonActions.action_steps,
					...elemAction?.content ?? [],
				];
			});

			return lessonActions;
		});

		const student_response = {
			answers: studentAnswers,
			context: props.element.context
		}
		
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: student_response 
		}

		// to enable in the student view
		if(!isNotStudent(props.currentCapabilities.role)) {
			submitStudentElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Action Step summary submitted")
					setAnswerSent(true)
					setStudentProgress(response.data.init[0]?.["library_progress"]);
				}
			})
		}
		else {
			setAnswerSent(true)
			displayToast("success", "This is just a test, Submission not recorded on admin view")
		}
	}

	const onActionStepEdit = (lessonId, elementId, actionData, showModal = true, isSoft = false) => {
		if(showModal) {
			setSelectedActionstep(actionData);
			setTargetLesson(lessonId);
			setTargetElement(elementId);
			setShowEditActionStepModal(true);
		}
		else {
			if(actionData.is_new || isSoft) {
				updateLessonElementActionSteps(lessonId, elementId, actionData);
			}
			else {
				onActionStepEditSubmit(lessonId, elementId, actionData);
			}
		}
	}

	const onActionStepAdd = (lessonId, elementId, value) => {
		let newActionData = createDefaultActionData(value, elementId);

		let newLessonsElementsActionSteps = lessonsElementsActionSteps.map((lesson) => {
			if(lesson.id === lessonId) {
				let newLesson = {...lesson};

				newLesson.action_steps = newLesson.action_steps.map((actionElem) => {

					if(actionElem.id === elementId) {
						let newActionElem = {...actionElem};

						newActionElem.content.push(newActionData);

						return newActionElem;
					}

					return actionElem;
				});

				return newLesson;
			}

			return lesson;
		});

		setSelectedActionstep(newActionData);
		setLessonsElementsActionSteps(newLessonsElementsActionSteps);
		setTargetElement(elementId);
		setTargetLesson(lessonId);
		setShowEditActionStepModal(true);
	}

	const updateLessonElementActionSteps = (lessonId, elementId, actionData, toReplaceActionData=null) => {
		let newLessonsElementsActionSteps = lessonsElementsActionSteps.map((lesson) => {
			if(lesson.id === lessonId) {
				let newLesson = {...lesson};

				newLesson.action_steps = newLesson.action_steps.map((actionElem) => {

					if(actionElem.id === elementId) {
						let newActionElem = {...actionElem};

						if(actionData.to_delete && !actionData.is_new) {
							newActionElem.content = newActionElem.content.filter((actionCont) => actionCont.id != actionData.id);
						}
						else {
							newActionElem.content = newActionElem.content.map((actionCont) => {
	
								if(actionCont.id === actionData.id) {
									let newActionCont = {...actionData};

									if(!isEmpty(toReplaceActionData)) {
										newActionCont = {...toReplaceActionData};
									}
									
									return newActionCont;
								}
	
								return actionCont;
							});
						}

						return newActionElem;
					}

					return actionElem;
				});

				return newLesson;
			}

			return lesson;
		});

		setLessonsElementsActionSteps(newLessonsElementsActionSteps);
	}

	const onActionStepEditSubmit = async (lessonId, elementId, actionData) => {
		try {
			let response;

			if(actionData.is_new) {
				let toSubmitData = {
					...actionData,
					users: [props.userDetails.user_id ?? props.userDetails.id],
				};

				response = await createActionstep(toSubmitData);
				
				displayToast("success", "Successfully added custom action step");
			}
			else if(actionData.to_delete) {
				response = await deleteActionstep(actionData.id);
			}
			else {
				let toSubmitData = {
					...actionData,
					action_step_id: actionData.id,
				};

				response = await updateActionstep(toSubmitData);
				
				displayToast("success", "Successfully edited action step");
			}

			if(response.data.init[0].status == 'ok') {
				updateLessonElementActionSteps(lessonId, elementId, actionData, response?.data?.data[0]);
			}
			else {
				displayToast("error", response.data.init[0]["message"]);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}

		setTargetLesson(null);
		setTargetElement(null);
	}

	const confirmActionStepRemove = () => {
		let toDeleteActionStep = {...selectedActionstep, to_delete: true};
		onActionStepEditSubmit(targetLesson, targetElement, toDeleteActionStep);
		updateLessonElementActionSteps(targetLesson, targetElement, toDeleteActionStep);
		setShowRemoveActionStepDialog(true);
		displayToast("success", "Action steps deleted.");
	}

	return (
		<>
			<YesNoDialog 
				message="Are you sure you want to permanently delete this action step?"
				open={showRemoveActionStepDialog}
				onClose={() => {
					setShowRemoveActionStepDialog(false);

				}}
				confirm={confirmActionStepRemove}
			/>
			<ActionstepCardEditModal 
				show={showEditActionStep && !isEmpty(selectedActionstep)}
				setShow={setShowEditActionStepModal}
				actionstepData={selectedActionstep}
				// elementSettings={currentActionstepSettings}
				onActionStepEditSubmit={(actionData) => {
					onActionStepEditSubmit(targetLesson, targetElement, actionData);
				}}
				onActionStepDelete={(actionData) => {
					let newActionData = {...actionData};
					newActionData.to_delete = true;
					
					setShowEditActionStepModal(false);

					if(newActionData.is_new) {
						updateLessonElementActionSteps(targetLesson, targetElement, newActionData);
					}
					else {
						setShowRemoveActionStepDialog(true);
					}
				}}
			/>
			<LessonAccordion {...props}>
				<div className="d-flex">
					{props.element?.title && (
						<ElementTitle>
							{ props.element?.title }
						</ElementTitle>
					)}
				</div>
				<div className="quiz-container">
					<span className="d-flex mb-3">
						<TextView className="me-1 font-size-large">
							{ props.element?.description }
						</TextView>
					</span>
					<div className="d-block">
						{(answerSent || isNotStudent(props.currentCapabilities.role)) ? (
							lessonsElementsActionSteps?.map((lesson) => {
								let mIndex = 0;
								let lessonActionSteps = lesson?.action_steps ?? [];

								if(lessonActionSteps.length === 0) {
									return null;
								}

								return (
									<div key={lesson?.lesson_id} className="mb-4">
										<TextView
											fontweight="semibold"
											customclasses="mb-2"
										>
											{lesson?.title}
										</TextView>
										<div className="ms-4">
											{lessonActionSteps.map((actionElem) => (
												actionElem?.content?.map((actionCont, j) => {
													mIndex++;

													return (
														<div className="mb-2 d-flex">
															<TextView customclasses="d-block mb-1">
																{mIndex}.
															</TextView>
															<div className="ps-2 d-flex flex-column">
																<TextView customclasses="mb-1">
																	{actionCont?.title}
																</TextView>
																{!isNotStudent(props.currentCapabilities.role) && (
																	<React.Fragment>
																		{!isEmpty(actionCont?.context?.completed_date) && (
																			<TextView fontweight="semibold">
																				Completed: <TextView fontcolor="black">{formatDate(actionCont?.context?.completed_date)}</TextView>
																			</TextView>
																		)}
																		<TextView fontweight="semibold">
																			Initial Deadline: <TextView fontcolor="black">{actionCont?.due_date}</TextView>
																		</TextView>
																	</React.Fragment>
																)}
															</div>
														</div>
													);
												})
											))}
										</div>
									</div>
								)
							})
						) : (
							<lessonActionStepContext.Provider value={{
								onActionStepEdit,
								onActionStepAdd,
							}}>
								<LessonCardSummary 
									customdeadline={props.element.context.allow_custom_deadline}
									lessonActionSteps={lessonsElementsActionSteps}
									isAdmin={isNotStudent(props.currentCapabilities.role)}
								/>
							</lessonActionStepContext.Provider>
						)}
					</div>
				</div>
				<div className="d-flex">
					{(!isNotStudent(props?.currentCapabilities?.role) && !answerSent) && (
						<BaseButton
							customclasses="ms-auto"
							onClick={() => {
								let allDeadlineIsSet = true;

								for(let lesson of lessonsElementsActionSteps) {
									for(let actionElem of lesson?.action_steps ?? []) {
										for(let step of actionElem?.content ?? []) {
											if(isEmpty(step?.due_date)) {
												allDeadlineIsSet = false;
												break;
											}
										}
									}
								}
								
								if(!allDeadlineIsSet) {
									displayToast("error", "Please select deadline on each action steps.")
									return 
								}

								if(isNotStudent(props.currentCapabilities.role) && answerSent) {
									setAnswerSent(false)
									getActionSteps()
								}

								submitStudentResponse();
							}}
						>
							Submit
						</BaseButton>
					)}

					{
						(
							!isNotStudent(props?.currentCapabilities?.role) && 
							props.element.last_element &&
							answerSent
						) && (
							<div className="d-flex ms-2 mt-2 w-100">
								<BaseButton 
									customclasses="ms-auto"
									onClick={() => {
										props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, studentProgress);
									}}
								>
									Next Element
								</BaseButton>
							</div>	
						)
					}
				</div>
			</LessonAccordion>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		selectedLibrary: state.dashboard?.libraries?.selected_library,
		currentCapabilities: state.auth.current_capabilities,
		userDetails : state.auth.user_details,
	}
}

const mapDispatchToProps = {
	setAI
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionSummaryElement);