import axios from "axios";
import { objectToUrlQuery } from "../../helpers/Functions";
import { DEFAULT_AUTH_HEADER } from "../../models/Constants";

export function updateElement(params) {
	let dataPost = new FormData();

	for(const [key, value] of Object.entries(params)) {
		if(key === "files" && Array.isArray(value)) {
			value.forEach((file, index) => {
				if ("context" in file && typeof file.context === "object") {
					if ("id" in file) {
					  dataPost.append(`files[${index}][id]`, file.id);
					}
					dataPost.append(`files[${index}][files]`, file.context.file);
					dataPost.append(`files[${index}][files_description]`, file.context.description);
				} 
				else if (file.files instanceof File) {
					dataPost.append(`files[${index}][files]`, file.files);
					dataPost.append(`files[${index}][files_description]`, file.files_description);
				}
				});
		}
		else if(key === "subtitles" && Array.isArray(value)) {
			value.forEach((subtitle, index) => {
				if("context" in subtitle && typeof subtitle.context === "object") {
					if("id" in subtitle) {
						dataPost.append(`subtitles[${index}][id]`, subtitle.id);
					}

					dataPost.append(`subtitles[${index}][file]`, subtitle.context.file);
					dataPost.append(`subtitles[${index}][language]`, subtitle.context.language);
				}
				else if(subtitle.file instanceof File) {
					dataPost.append(`subtitles[${index}][file]`, subtitle.file);
					dataPost.append(`subtitles[${index}][language]`, subtitle.language);
				}
			});
		}
		else if (typeof value === "object") {
			if (value instanceof File) {
				dataPost.append(key, value, value.name);
			} 
			else {
				dataPost.append(key, JSON.stringify(value));
			}
		} 
		else {
			dataPost.append(key, value);
		}
	}

	return axios.post(process.env.REACT_APP_API_URL + "/element/update-owner-element", dataPost);
}

export function sortElements(params, element_id) {
	return axios.put(process.env.REACT_APP_API_URL + "/element/sort-owner-element/" + element_id, params);
}

export function deleteElement(params, element_id) {
	return axios.delete(process.env.REACT_APP_API_URL + "/element/delete-owner-element/" + element_id, {params})
}

export function submitStudentElementResponse(params) {
	if(params.student_response instanceof File){
		let dataPost = new FormData();

		for(const [key, value] of Object.entries(params)) {
			if (value instanceof File) {
				dataPost.append(key, value, value.name);
			} 
			else {
				dataPost.append(key, JSON.stringify(value));
			}
		}
		return axios.post(process.env.REACT_APP_API_URL + "/element/submit-student-element-response", dataPost);
	}
	else {
		return axios.post(process.env.REACT_APP_API_URL + "/element/submit-student-element-response", params);
	}

	return
	// 
}

export function getLessonSurveys(params){
	return axios.get(process.env.REACT_APP_API_URL + "/element/get-lesson-surveys?" + objectToUrlQuery(params));
}

export function getLibraryLessonsTranscripts(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/element/get-library-lessons-transcripts?" + objectToUrlQuery(params));
}

export function getElementQuickAccess(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/get-element-with-code?" + objectToUrlQuery(params), DEFAULT_AUTH_HEADER);
}

export function quickSubmitElementResponse(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/quick-submit-element-response", params, DEFAULT_AUTH_HEADER);
}

export function getSurveyResults(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/get-survey-results?" + objectToUrlQuery(params), DEFAULT_AUTH_HEADER);
}

export function getWordCloudResult(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/get-word-cloud-results?" + objectToUrlQuery(params), DEFAULT_AUTH_HEADER);
}

export function getLessonTranscripts(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/element/get-lesson-elements-transcripts?" + objectToUrlQuery(params));
}

export function updateAIPrompts(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/element/update-master-prompt", params);
}

export function downloadsToEmail(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/element/download-to-email?" + objectToUrlQuery(params));
}

export function quickDownloadsToEmail(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/download-to-email?" + objectToUrlQuery(params), DEFAULT_AUTH_HEADER);
}

export function textElementToEmail(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/element/text-to-email", params);
}

export function quickTextElementToEmail(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/text-to-email", params, DEFAULT_AUTH_HEADER);
}

export function getConversationStarters(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/element/conversation-starters?" + objectToUrlQuery(params));
}

export function saveConversationStarters(params) {
	let dataPost = new FormData();

	for(const [key, value] of Object.entries(params)) {
		if (typeof value === "object") {
			if (value instanceof File) {
				dataPost.append(key, value, value.name);
			} 
			else {
				dataPost.append(key, JSON.stringify(value));
			}
		} 
		else {
			dataPost.append(key, value);
		}
	}
	return axios.post(process.env.REACT_APP_API_URL + "/element/conversation-starters", dataPost);
}

export function getStudentAttachmentResponse(attachment_id, params) {
	return axios.get(process.env.REACT_APP_API_URL + "/element/get-student-attachment-responses/" + attachment_id + "?" +objectToUrlQuery(params));
}

export function getRatingsResult(elementId) {
	return axios.get(process.env.REACT_APP_API_URL + "/element/get-ratings-results?element_id=" + elementId);
}

export function getElementComments(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/element-comments" + "?" + objectToUrlQuery(params), DEFAULT_AUTH_HEADER);
}

export function submitElementComment(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/element-comment", params, DEFAULT_AUTH_HEADER);
}

export function toggleCommentReaction(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/update-comment-reaction", params, DEFAULT_AUTH_HEADER);
}

export function getCommentTags(string) {
	return axios.get(process.env.REACT_APP_API_URL + "/comment-tags?tag_name=" + string, DEFAULT_AUTH_HEADER);
}

export function getSurveyExplanations(elementId, params) {
	return axios.get(process.env.REACT_APP_API_URL + `/d/element/${elementId}/survey/explanations?` + objectToUrlQuery(params), DEFAULT_AUTH_HEADER);
}