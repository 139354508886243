import React from "react";
import TextView from "./Typography";

const PostProfile = () => {
	return (
		<div className="d-flex flex-row mb-2">
			{/* <div className="d-inline-flex pe-3">
				image
			</div> */}
			<div className="d-inline-flex flex-column">
				<TextView fontsize="medium">
					<b>Name</b>
				</TextView>
				<TextView fontsize="secondary">
					2 January 2023 19:35 
				</TextView>
			</div>
		</div>
	)
}

export default PostProfile;