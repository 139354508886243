import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => ({
	minWidth: '9rem', // Minimum width to ensure consistency
  	width: '100%', // Expand to fill the container
	height: 16,
	borderRadius: 20,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: value === 100 ? '#4CAF50' : (theme.palette.mode === 'light' ? '#37CB97' : '#C7D4DE'),
	},
}));

const BaseProgressBar = ({...props}) => {
	return (
		<div
			className={`d-block w-100 align-content-start text-right ${props?.customclasses}`}
			{...props}
	>
			<div className='d-flex'>
				<span className={`${props.titleclasses}`}>{props.title}</span>
				<span style={{marginLeft : "auto"}}>{props.value}%</span>
			</div>
			<BorderLinearProgress className="w-100" variant="determinate" value={props.value} />
		</div>
	)
}

export default BaseProgressBar;