import { Modal } from "react-bootstrap";
import BaseButton from "../../../../theme/partials/BaseButton";
import BaseTextField from "../../../../theme/partials/BaseTextField";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { setAI } from "../../../redux/slices/dashboardSlice";
import { connect } from "react-redux";
import { TEXT_ELEMENT } from "../../../../models/Constants";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, InputLabel } from '@mui/material';
import LanguageDropdown from "../../../../theme/partials/LanguageDropdown";
import { isEmpty } from "../../../../helpers/Functions";

const PromptDialog = ({ ...props }) => {
	const messageLines = props?.message?.split('\n');

	return (
		<Dialog open={props.open}>
			<DialogTitle>Message sent to AI</DialogTitle>
			<DialogContent>
			{messageLines?.map((line, index) => (
				<div key={index}>
					<DialogContentText>{line}</DialogContentText>
					{index < messageLines?.length - 1 && <div style={{ marginBottom: '16px' }} />}
				</div>
			))}
			</DialogContent>
			<DialogActions>
				<Button onClick={() => props.onClose(false)} color="primary">Ok</Button>
			</DialogActions>
		</Dialog>
	);
};


function AITestForm ({setAI, ...props}) {
	const [showHidden, setShowHidden] = useState(false)
	const [openDialog, setOpenDialog] = useState(false)

	useEffect(() => {
		if(typeof props?.setFieldValue !== "undefined" && props.show) {
			props?.setFieldValue("ai_prompt", props.ai?.prompt)

			if(props.ai.type == TEXT_ELEMENT) {
				props?.setFieldValue("description", props.ai?.string)
			}
		}
	}, [props.ai]);

	const changeLanguageCallback = (selectedOption) => {
		setAI({
			...props.ai,
			prompt_params: {
				...props.ai.prompt_params,
				language_code: selectedOption.key,
				language: selectedOption.name
			}
		});
	}

	const promptTitle = props.params ? 
		(<>Customize Prompt <br/> {"(Ex. {{industry}} {{team}} {{role}} {{product}} {{product_description}} {{language}})"}</>) 
		: "Customize Prompt"

	return(<>
		<Modal
			dialogClassName="stacked-modal"
			size="lg" 
			show={props.show} 
			onHide={props.toggleShow}
		>
			<Modal.Header closeButton>
				<Modal.Title>Test AI</Modal.Title>
			</Modal.Header>
			<Modal.Body>

			<PromptDialog
				open={openDialog}
				onClose={setOpenDialog}
				message={props?.ai?.message}
			/>

			{
				(props.ai.type == TEXT_ELEMENT && props.ai?.settings?.defaultText == 1) && (
					<BaseTextField
						title="Context"
						placeholder="Enter Context"
						minRows={2}
						maxRows={10}
						multiline
						titleclass="font-size-medium color-septenary"
						name="ai_prompt"
						value={props.ai?.string}
						onChange={(e) => setAI({ ...props.ai, string: e.target.value })}
					/>
				)
			}
			
			<BaseTextField
				title={promptTitle}
				titleclass="font-size-medium color-septenary"
				placeholder="Enter Prompt"
				name="ai_prompt"
				minRows={2}
				maxRows={10}
				multiline
				value={props.ai?.prompt}
				onChange={(e) => {
					setAI(({ ...props.ai, prompt: e.target.value }))
				}}
			/>
			{
				props.ai.type == TEXT_ELEMENT ? <>
					<div className="d-flex">
						<BaseTextField
							style={{ maxWidth: "98%" }}
							title="Industry"
							titleclass="font-size-medium color-septenary"
							name="industry"
							value={props.ai?.prompt_params?.industry}
							onChange={(e) => setAI({ ...props.ai, prompt_params: { ...props.ai.prompt_params, industry: e.target.value } })}

						/>
						<BaseTextField
							style={{ maxWidth: "98%" }}
							title="Role"
							titleclass="font-size-medium color-septenary"
							name="role"
							value={props.ai?.prompt_params?.role}
							onChange={(e) => 
								setAI({ ...props.ai, prompt_params: { ...props.ai.prompt_params, role: e.target.value } })
							}
						/>
					</div>
					{
						showHidden && <>
							<div className="d-flex" >
								<BaseTextField
									style={{ maxWidth: "98%" }}
									title="Product"
									titleclass="font-size-medium color-septenary"
									name="product"
									value={props.ai?.prompt_params?.product}
									onChange={(e) => 
										setAI({ ...props.ai, prompt_params: { ...props.ai.prompt_params, product: e.target.value } })
									}
								/>
								<BaseTextField
									style={{ maxWidth: "98%" }}
									title="Team"
									titleclass="font-size-medium color-septenary"
									name="team"
									value={props.ai?.prompt_params?.team}
									onChange={(e) => 
										setAI({ ...props.ai, prompt_params: { ...props.ai.prompt_params, team: e.target.value } })
									}
								/>
							</div>
							<BaseTextField
								title="Product Description"
								titleclass="font-size-medium color-septenary"
								name="product_description"
								value={props.ai?.prompt_params?.product_description}
								onChange={(e) => 
									setAI({ ...props.ai, prompt_params: { ...props.ai.prompt_params, product_description: e.target.value } })
								}
							/>
							<LanguageDropdown
								customclasses="mb-3"
								title="Language"
								name="language_code"
								value={!isEmpty(props.ai?.prompt_params?.language_code) ? props.ai?.prompt_params?.language_code : 0}
								callback={(e) => changeLanguageCallback(e)}
							/>
						</>
					}
					<Link onClick={()=> setShowHidden(!showHidden)}>
						{showHidden ? "Hide Other Options" : "Show Other Options"}
					</Link>
				</>
				: (
					<BaseTextField
						title="Sample Response"
						titleclass="font-size-medium color-septenary"
						placeholder="Enter a sample of student response"
						name="student_response"
						value={props.ai?.string}
						onChange={(e) => setAI({ ...props.ai, string: e.target.value })}
					/>
				)
			}

			<div className="d-flex justify-content-center">
				<BaseButton
					className="color-white bg-primary align-self-end m-2"
					issubmitting={props.testAISubmitting}
					onClick={(e) =>{
						e.stopPropagation();
						props.test(props.ai)
					}}
				>
					TEST
				</BaseButton>
			</div>
			
			{
				(props?.ai.response != "") && (<>
					<div className='d-flex justify-content-between'>
						<InputLabel className="mt-3 mb-1 font-size-medium color-septenary">Response</InputLabel>
						<Link
							className="text-right"
							onClick={() => setOpenDialog(true)}
						>
							View Sent Prompt
						</Link>
					</div>
					<div className="p-2 outline-black">
						<div dangerouslySetInnerHTML={{ __html: props?.ai.response?.replace(/<br>/g, "") }} />
					</div>
					
				</>)
			}
			</Modal.Body>
		</Modal>
	</>)	
}

const mapStateToProps = (state) => {
	return {
		ai: state.dashboard?.ai
	}
}

const mapDispatchToProps = {
	setAI
}

export default connect(mapStateToProps, mapDispatchToProps)(AITestForm);