import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import BaseButton from './BaseButton';
import { isEmpty } from '../../helpers/Functions';

const RichTextEditor = ({disableexpanded, ...props}) => {
	const disableExpandedExists = isEmpty(disableexpanded);

	const fontColors = [
		"#FF0000", // Red
		"#FF4500", // Orange-Red
		"#FFA500", // Orange
		"#FFFF00", // Yellow
		"#008000", // Green
		"#0000FF", // Blue
		"#87CEEB", // Light Sky Blue
		"#ADD8E6", // Light Blue
		"#B0E0E6", // Powder Blue
		"#20B2AA", // Light Sea Green
		"#32CD32", // Lime Green
		"#00FF7F", // Spring Green
		"#008080", // Teal
		"#000080", // Navy
		"#4B0082", // Indigo
		"#9400D3", // Dark Violet
		"#8A2BE2", // Blue Violet
		"#483D8B", // Dark Slate Blue
		"#2E8B57", // Sea Green
		"#000000", // Black
		"#808080", // Gray
	];

	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'],			
		[{ 'list': 'ordered' }, { 'list': 'bullet' }],
		[{ 'script': 'sub' }, { 'script': 'super' }],		
		[{ 'indent': '-1' }, { 'indent': '+1' }],			
		[{ 'direction': 'rtl' }],							
		[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
		[{ 'color': fontColors}],                               
		[{ 'align': [] }],
		['link'],     
		['clean'], 										
	];

	
	const modules = {
		clipboard: {
			matchVisual: false,
		},
		toolbar: toolbarOptions,
	};

	var formats = [
		'bold',
		'color',
		'italic',
		'script',
		'strike',
		'underline',
		'header',
		'indent',
		'list',
		'align',
		'direction'
	];

	const [containerStyle, setContainerStyle] = useState({height: "10rem", marginBottom: "3.5rem"})
	const [isExpanded, setIsExpanded] = useState(false)

	return(
		<div 
			className="d-block p-0"
			style={props.editorstyle || containerStyle}
		>
			<ReactQuill 
				theme="snow"
				style={{height: "100%", border: 0}}
				modules ={modules}
				formats={formats}
				{...props}
			/>
			{
				!isExpanded ? (
					disableExpandedExists && (
						<IconButton
							onClick={() => {
								const viewportWidth = window.innerWidth;
								const containerWidth = viewportWidth * 0.8;
								const leftPosition = (viewportWidth - containerWidth) / 2;
		
								setContainerStyle(props.expandedstyle || {
									backgroundColor: "#fff",
									height: "80vh",
									width: "80vw",
									marginBottom: "3.5rem",
									position: "fixed",
									zIndex: 9999,
									top: "10vh",
									left: `${leftPosition}px`,
								});
								
								setIsExpanded(true)
							}}
						>
							<OpenWithIcon/>
						</IconButton>
					)
				) : (
					<BaseButton
						style={{
							width: '100%',
							position: 'relative'
						}}
						onClick={() => {
							setContainerStyle({height: "10rem", marginBottom: "3.5rem"})
							setIsExpanded(false)
						}}
					>
						Exit Expanded View
					</BaseButton>
				)
			}
		</div>
	)
}

export default RichTextEditor;