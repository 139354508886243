import axios from "axios";
import { DEFAULT_AUTH_HEADER } from "../../models/Constants";

export function aiChatCompletion(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/openai/create-chat-completion" , params);
}

export function aiQuiz(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/openai/create-ai-quiz" , params);
}

export function quickAiChatCompletion(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/quick-ai-completion/1" , params, DEFAULT_AUTH_HEADER);
}
