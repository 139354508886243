import React, { useEffect, useState } from "react";

import BaseTextField from "../../../../../theme/partials/BaseTextField";
import BaseCheckbox from "../../../../../theme/partials/BaseCheckbox";
import { Link } from "react-router-dom";
import { setAI } from "../../../../redux/slices/dashboardSlice";
import { connect } from "react-redux";
import { TEXT_ELEMENT } from "../../../../../models/Constants";
import DatePicker from "../../../../../theme/partials/DatePicker";
import { InputLabel, Accordion ,AccordionSummary, AccordionDetails, CircularProgress } from "@mui/material";
import BaseSwitch from "../../../../../theme/partials/BaseSwitch";
import BaseSelect from "../../../../../theme/partials/BaseSelect";
import { getLessonTranscripts } from "../../../../crud/element.crud";
import displayToast from "../../../../../theme/partials/DisplayToast";
import { isEmpty } from "../../../../../helpers/Functions";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextView from "../../../../../theme/partials/Typography";
import { getAIPrompts } from "../../../../crud/masterdata.crud";
import RichTextEditor from "../../../../../theme/partials/RichTextEditor";

const ElementTranscripts = ({...props}) => {

	return (<>
		<TextView>Select all transcript to be added</TextView>
		{
			props.transcriptlist?.map((transcript, index) => (
				<div 
					key={index}
					className="py-3"
				>
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<div className="d-block">
								<BaseCheckbox 
									title={transcript.description}
									checked={props?.selected?.some(obj => obj.id ===  transcript.element_id)}
									disableTitleClick={true}
									onChange={() => {
										let newSelected = [...props?.selected]
										
										if(newSelected?.some(obj => obj.id === transcript.element_id)) {
											newSelected = newSelected.filter(obj => obj.id !==  transcript.element_id);
										}
										else {
											newSelected = [
												...newSelected,
												{
													id: transcript.element_id,
													description: transcript.description,
													transcript: transcript.transcript
												}
											]
										}

										props.setSelected(newSelected)
									}}
								/>
							</div>
						</AccordionSummary>
						<AccordionDetails>
							<TextView> {transcript.transcript} </TextView>
						</AccordionDetails>
					</Accordion>
				</div>
			))
		}
	</>)
}

const AIVariation = ({ setAI, ...props }) => {
	const [masterPrompts, setMasterPrompts] = useState([])
	const [selectedPrompt, setSelectedPrompt] = useState()
	const [showTranscripts, setShowTranscripts] = useState(true)

	const [transcriptList, setTranscriptList] = useState([])
	const [selectedTranscript, setSelectedTranscript] = useState([])
	
	const [noMasterPrompt, setNoMasterPrompt] = useState(false)

	function changeStatus(value) {
		return value == 1 ? 0 : 1;
	}

	function numberToBool(value) {
		return value == 1 ? true : false;
	}
		
	const reconstructSelect = (arr) => {
		if(Array.isArray(arr)) {
			const array = arr?.map((value) => {
				return { value: value.id, label: value.title, prompt: value.prompt};
			});
			return array;
		}
	}

	const getTextPrompt = () => {
		let params = {
			element_type_id: TEXT_ELEMENT
		}

		getAIPrompts(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				const masterPromptOptions = reconstructSelect(response.data.data[0])
				setMasterPrompts(masterPromptOptions)

				masterPromptOptions.length == 0 ? setNoMasterPrompt(true) : setNoMasterPrompt(false)

				if(masterPromptOptions.length == 1 && props.values.ai_master_prompt == 1){
					setSelectedPrompt(masterPromptOptions[0])
				}
			}
		})
	}

	useEffect(() => {
		if(props.values.element_id != 0){
			if(!isEmpty(props.values.ai_transcript)){
				setShowTranscripts(true)
				setSelectedTranscript(JSON.parse(props.values?.ai_transcript))
			}
		}
	}, [props.values.element_id])
	
	useEffect(() => {
		if(!showTranscripts){
			props.setFieldValue("ai_transcript", "")
		}
		else {
			let newArr = [...selectedTranscript]
			let transcript = newArr.sort((a, b) => a.id - b.id);

			props.setFieldValue("ai_transcript", transcript)
		}
	},[selectedTranscript, showTranscripts])


	const  getInLessonTranscript = () => {
		let  params = {
			library_party_id : props.values.library_id,
			module_id : props.values.module_resource_id,
			lesson_id : props.values.lesson_resource_id
		}

		getLessonTranscripts(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				setTranscriptList(response.data.data)
			}
		})
	}

	useEffect(() => {
		getTextPrompt()
		getInLessonTranscript()
	}, [])

	useEffect(() => {
		if(props.values.ai_master_prompt == 1 && !isEmpty(props.values.ai_prompt)){
			let i = masterPrompts.findIndex(obj => obj.prompt === props.values.ai_prompt);
			setSelectedPrompt(masterPrompts[i])
		}
	}, [masterPrompts, props.values.element_id])

	useEffect(() => {
		if(!isEmpty(selectedPrompt)) {
			if(props.values.ai_master_prompt == 1) {
				props.setFieldValue("ai_prompt", selectedPrompt.prompt)
			}
		}
	}, [selectedPrompt])

	useEffect(() => {
		if(props.values.ai_master_prompt == 0 && props.values.is_edit == 0) {
			props.setFieldValue("ai_prompt", "");
		}

		if(masterPrompts.length == 1 && props.values.ai_master_prompt == 1){
			setSelectedPrompt(masterPrompts[0])
		}
	}, [props.values.ai_master_prompt])


	return (<>
		{
			numberToBool(props.values.ai_customized) ? (
				<BaseTextField
					name="description"
					multiline
					minRows={4}
					maxRows={10}
					title="Context"
					placeholder="Enter Context" 
					value={props.values.description}
					onChange={(e) => {
						props.setFieldValue("description", e.target.value)
					}}
				/>
			) : (<>
				<InputLabel className="font-size-medium color-septenary mb-2">Context</InputLabel>
				<RichTextEditor 
					theme="snow"
					style={{height: "100%"}}
					value={props.values.description}
					onChange={(e)=> {
						props.setFieldValue("description", e)
					}} 
				/>
			</>)
		}
		<div className="d-flex">
			<div className="d-block me-auto">
				<BaseCheckbox
					title="Customize with AI"
					customclasses="modal-checkbox"
					align="start"
					name="ai_customized"
					checked={numberToBool(props.values.ai_customized)}
					value={props.values.ai_customized}
					onChange={
						(e) => {
							props.setFieldValue("ai_customized", changeStatus(props.values.ai_customized))
						}
					}
					onBlur={props.handleBlur}
					color="primary"
				/>
			</div>
			{
				(props.values.ai_customized == 1) && (
					<BaseSwitch
						title="Use Master Prompt"
						customclasses="ms-auto"
						className="ms-auto"
						checked={props?.values?.ai_master_prompt == 1}
						onChange={() => {
							if(props.values.ai_master_prompt == 1){
								props.setFieldValue("ai_prompt", "")
							}

							props.setFieldValue("ai_master_prompt", changeStatus(props?.values?.ai_master_prompt))
						}}
					/>
				)
			}
		</div>
		
		{
			numberToBool(props.values.ai_customized) && (<>
				{
					(props?.values?.ai_master_prompt == 1) ? (
						(isEmpty(masterPrompts)) ? (
							<div className="d-flex justify-content-center py-2">
								{
									noMasterPrompt ? (
										<TextView className="font-size-small color-secondary">No master prompt available</TextView>
									) : (
										<CircularProgress />
									)
								}
							</div>
						) : (<>
							<BaseSelect 
								title="Master Prompt"
								className="mb-2"
								value={selectedPrompt}
								options={masterPrompts}
								onChange={(e) => {
									setSelectedPrompt(e)
								}}
							/>
							<div className="d-flex">
								<Link 
									className="ms-auto d-block"
									onClick={() => {
										setShowTranscripts(!showTranscripts)
									}}
								>
									{ showTranscripts ? "Remove Transcripts" :"Add Video Transcript"}
								</Link>
							</div>
							{
								(showTranscripts) && (
									<ElementTranscripts 
										transcriptlist={transcriptList}
										selected={selectedTranscript}
										setSelected={setSelectedTranscript}
									/>
								)
							}
						</>)
					) : (<>
						<BaseTextField
							title={<>Customize Prompt <br/> {"(Ex. {{industry}} {{team}} {{role}} {{product}} {{product_description}} {{language}})"}</>}
							titleclass="font-size-medium color-septenary"
							name="ai_prompt"
							multiline
							minRows={2}
							maxRows={10}
							value={props.values?.ai_prompt}
							onBlur={props.handleBlur}
							onChange={props.handleChange}
						/>
						<Link 
							className="d-block"
							onClick={() => {
								props.testAIShowToggle(!props.testAIShowOptions)
								const ai_params = props?.ai?.prompt_params;

								setAI({
									type: TEXT_ELEMENT,
									response: "",
									string: props.values.description,
									prompt: props.values.ai_prompt || "",
									settings : {
										defaultText : props.values.ai_default_text,
										autoReplace : props.values.ai_auto_replace_text
									},
									prompt_params : ai_params || {
										industry : "",
										product : "",
										position : "",
										team : "",
										product_description : "",
									}
								})
							}}
						>
							{(props.testAIShowOptions) ? "Close" : "Test AI"}
						</Link>
					</>)
				}
				<div className="d-block">
					<BaseCheckbox
						title="Use default text for AI customization"
						name="ai_default_text"
						align="start"
						value={numberToBool(props.values.ai_default_text)}
						checked={numberToBool(props.values.ai_default_text)}
						onChange={
							(e) => {
								props.setFieldValue("ai_default_text", changeStatus(props.values.ai_default_text))
							}
						}
						onBlur={props.handleBlur}
						color="primary"
					/>
				</div>
				<div className="d-block">
					<BaseCheckbox
						title="Automatically replace the default text"
						name="ai_auto_replace_text"
						align="start"
						value={numberToBool(props.values.ai_auto_replace_text)}
						checked={numberToBool(props.values.ai_auto_replace_text)}
						onChange={
							(e) => {
								props.setFieldValue("ai_auto_replace_text", changeStatus(props.values.ai_auto_replace_text))
							}
						}
						onBlur={props.handleBlur}
						color="primary"
					/>
				</div>
				<div className="d-block">
					<BaseCheckbox
						title="Customize message before the AI output"
						name="ai_is_custom_label"
						value={numberToBool(props.values.ai_is_custom_label)}
						checked={numberToBool(props.values.ai_is_custom_label)}
						onChange={
							(e) => {
								props.setFieldValue("ai_is_custom_label", changeStatus(props.values.ai_is_custom_label))
							}
						}
						onBlur={props.handleBlur}
						color="primary"
					/>
				</div>
				{
					numberToBool(props.values.ai_is_custom_label) && (
						<BaseTextField
							title="Customize Label"
							name="ai_custom_label"
							titleclass="font-size-medium color-septenary"
							value={props.values.ai_custom_label}
							onBlur={props.handleBlur}
							onChange={props.handleChange}
						/>
					)
				}
			</>)
		}
	</>)

}

const mapStateToProps = (state) => {
	return {
		modules: state.dashboard?.libraries?.selected_library?.modules,
		ai: state.dashboard?.ai
	}
}

const mapDispatchToProps = {
	setAI
}

export default connect(mapStateToProps, mapDispatchToProps)(AIVariation);
