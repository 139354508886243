import axios from "axios";
import { objectToUrlQuery } from "../../helpers/Functions";

export function getOwnerCompanyStudentsList(slug,params) {
	return axios.get(process.env.REACT_APP_API_URL + "/company/get-owner-company-users/" + slug + "?"+ objectToUrlQuery(params));
}

export function getOwnerCompaniesList(params){
    return axios.get(process.env.REACT_APP_API_URL + '/company/get-owner-companies-list?' + objectToUrlQuery(params))
}

export function updateOwnerCompany(slug, params){
    let dataPost = new FormData();
	
	for (const [key, value] of Object.entries(params)) {
		if (typeof value === 'object') {
			if (value instanceof File) { // Check if the value is a file object
				dataPost.append(key, value, value.name); // Append the file object with its name
			} 
			else {
				dataPost.append(key, JSON.stringify(value));
			}
		} 
		else {
			dataPost.append(key, value);
		}
	}

    return axios.post(process.env.REACT_APP_API_URL + '/company/update-owner-company/' + slug , dataPost)
}

export function createNewCompany(params){
    let dataPost = new FormData();

	for(const [key, value] of Object.entries(params)) {
		dataPost.append(key, value);
	}

    return axios.post(process.env.REACT_APP_API_URL + '/company/create-new-company', dataPost)
}

export function getMentors(params){
	return axios.get(process.env.REACT_APP_API_URL + '/company/mentors?' + objectToUrlQuery(params));
}

export function removeMentorFromLibrary(data){
	return axios.delete(process.env.REACT_APP_API_URL + '/company/mentors/libraries', {
		data: data,
	});
}

export function getUsers(params) {
	return axios.get(process.env.REACT_APP_API_URL + '/user?' + objectToUrlQuery(params));
}