import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Accordion, AccordionSummary, AccordionDetails} from "../../Accordion";
import TextView from "../../Typography";
import { ElementTypeIcon } from "./ElementTypeIcon";
import ActionIconButton from "../../ActionIconButton";
import { findNumbersOnArray, isEmpty, isPageQuickAccess } from "../../../../helpers/Functions";
import { getElementType } from "../../../../helpers/Utils";
import { isNotStudent } from "../../../../helpers/Utils";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import {
	VIDEO_ELEMENT,
	REFLECTION_ELEMENT,
	SURVEY_ELEMENT,
	LINK_ELEMENT,
	IMAGE_ELEMENT,
	AUDIO_ELEMENT,
	DOWNLOAD_ELEMENT,
	TEXT_ELEMENT,
	DOCUMENT_ELEMENT,
	SIGNATURE_ELEMENT,
	QUIZ_ELEMENT,
	ACTIONS_STEP_ELEMENT,
	WORD_CLOUD_ELEMENT,
	PERMISSIONS_EDIT_LIB_OUTLINE
} from '../../../../models/Constants';
import SortingButtons from "../../SortingButtons";
import YesNoDialog from "../../YesNoDialog";
import { useLocation } from "react-router-dom";
import { useLessonDetailsContext } from "../../../../app/dashboard/libraries/LessonDetails";

const LessonAccordion = ({currentCapabilities, ...props}) => {
	const { createCopy } = useLessonDetailsContext();

	const title = () => {
		if(props.element?.element_type_id === REFLECTION_ELEMENT) {
			return "Shared: How does this apply to you?";
		}
		else if (props.element?.element_type_id === QUIZ_ELEMENT) {
			return "Quiz Time!";//todo: count number of quiz in library then determine question number
		}
		else if (props.element?.element_type_id === TEXT_ELEMENT) {
			return isEmpty(props.element?.title) ? "Information" : props.element?.title;
		}
		else if (props.element?.element_type_id === ACTIONS_STEP_ELEMENT) {
			return "Time to Act: Our Action Steps!";
		}
		else if (
			props.element?.element_type_id === WORD_CLOUD_ELEMENT ||
			props.element?.element_type_id === REFLECTION_ELEMENT ||
			props.element?.element_type_id === SURVEY_ELEMENT
			) {
			const title = isNotStudent(currentCapabilities?.role) ? 
				(
					<TextView className="ms-1">
						{props.element?.element_type_id === SURVEY_ELEMENT ? "Survey" : 
							(
								props.element?.element_type_id === WORD_CLOUD_ELEMENT ? "Word Cloud" : "Reflection"
							)
						} - 
						<span className="ms-1" style={{fontWeight: "normal"}}>
							{props.element?.context?.number_of_respondent || 0} Respondents 
						</span>
					</TextView>
				)
				: "Survey"
			return title
		}
		else {
			if(isEmpty(props.element?.title)) {
				return getElementType(props.element?.element_type_id);
			}
			else {
				return props.element?.title;
			}
		}
	}

	const [permissions, setPermissions] = useState([])
	const [isExpanded, setIsExpanded] = useState(true);

	useEffect(() => {
		if(!isEmpty(currentCapabilities)){
			setPermissions(currentCapabilities.company?.roles.find(role => role.role_id == currentCapabilities.role)?.permissions);
		}
	}, [currentCapabilities])

	useEffect(() => {
		if(isEmpty(props?.expanded)) {
			return;
		}

		setIsExpanded(props?.expanded);
	}, [props?.expanded]);
	
	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);

	const [elementRemoveMessage, setElementRemoveMessage] = useState(false)
	const [showCopyDialog, setShowCopyDialog] = useState(false);

	const handleRemoveToggle = (status = false) => {
		if(status != false) {
			setElementRemoveMessage(true);
		}
		else{
			setElementRemoveMessage(false);
		}
	};

	const onCopyElement = (element) => {
		createCopy(element);
	}
	
	return (<>
		<YesNoDialog
			message = {`Would you like to remove the element?`}
			open={elementRemoveMessage}
			item={props.element}
			onClose={handleRemoveToggle}
			confirm={props.deleteElement}
		/>

		<YesNoDialog
			message = {`Would you like to create a copy of this element?`}
			open={showCopyDialog}
			item={props.element}
			onClose={setShowCopyDialog}
			confirm={onCopyElement}
		/>

		<Accordion className="mb-4 lesson-accordion" defaultExpanded={true} onChange={(...params) => {
			setIsExpanded(params?.[1]);
			if(!isEmpty(props?.onChange)) {
				props?.onChange(...params);
			}
		}} expanded={isExpanded} isExpanded={isExpanded}>
			<AccordionSummary className="d-flex bg-white" expanded={isExpanded}>
				<div className="d-flex align-items-center w-100">
					<ElementTypeIcon elementtype={props.element?.element_type_id} />
					<TextView customclasses="ms-3 flex-grow-1" fontsize="large">
						<b>{ title() }</b>
					</TextView>
						{
							(	
								isNotStudent(currentCapabilities.role) && 
								findNumbersOnArray(permissions, [PERMISSIONS_EDIT_LIB_OUTLINE])
							) && (<>
								<div className="me-3 d-flex">
									<SortingButtons
										itemtype="element"
										handleSorting={props.sortElement}
										fontsize="font-size-large"
										index={props.elementKey}
										item={props.element}
										itemlength={props.elementsLength}
									/>
								</div>
								<ActionIconButton
									icon={<EditIcon />}
									icontooltip="Edit this element."
									customclasses="me-3 lesson-accordion-action-btns"
									onClick={(e) => {
										e.stopPropagation();
										props.setEditElement(props.element)
										props.setShowElementModal(true);
									}}
								/>					
								<ActionIconButton
									icon={<DeleteIcon />}
									icontooltip="Delete this element."
									customclasses="me-3 lesson-accordion-action-btns"
									onClick={(e) => {
										e.stopPropagation();
										handleRemoveToggle(props.element)
									}}
								/>
								<ActionIconButton
									icon={<ContentCopyIcon />}
									icontooltip="Copy this element."
									customclasses="me-3 lesson-accordion-action-btns"
									onClick={(e) => {
										e.stopPropagation();
										setShowCopyDialog(true);
									}}
								/>
								<ActionIconButton
									icon={<AddCircleOutlineIcon />}
									icontooltip="Add new element below."
									customclasses="me-3 lesson-accordion-action-btns"
									onClick={(e) => {
										e.stopPropagation();
										props.addElement(props.element.sort + 1);
									}}
								/>
							</>)
						}
				</div>
			</AccordionSummary>

			<AccordionDetails className={isQuickAccess ? "px-1" : ""}>
				{ props.children }
			</AccordionDetails>
		</Accordion>
	</>)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
	}

	return data;
}

export default connect(mapStateToProps)(LessonAccordion);