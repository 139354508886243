import { FormControlLabel, Radio, styled  } from '@mui/material';

const StyledRadio = styled(Radio)(({ theme, checkedcolor }) => ({
	color: 'gray', // Unselected color
	'&.Mui-checked': {
	  color: checkedcolor || 'green', // Selected color (use the provided checkedcolor or default to 'green')
	},
}));  

const BaseRadioButton = ({...props}) => {
	return <FormControlLabel
				className={`${props.customclasses}`}
				control={
					props.selectcolor ? (
						<StyledRadio
							checkedcolor={props.selectcolor}
							{...props}
						/>
					) : (
						<Radio
							{...props}
						/>
					)
				}
				label={props.title}
  			/>
}

export default BaseRadioButton;