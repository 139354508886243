import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "../../../../../helpers/Functions";
import { getLessonSurveys } from "../../../../crud/element.crud";
import displayToast from "../../../../../theme/partials/DisplayToast";
import { InputLabel } from "@mui/material";
import PageTabs, { TabPanel } from "../../../../../theme/partials/PageTabs";
import BaseButton from "../../../../../theme/partials/BaseButton";
import TextView from "../../../../../theme/partials/Typography";
import { Link } from "react-router-dom";
import RichTextEditor from "../../../../../theme/partials/RichTextEditor";
import BaseSelect from "../../../../../theme/partials/BaseSelect";

const TextVariation = ({ ...props }) => {
	const [showOptions, setShowOptions] = useState();

	const [moduleOptions, setModuleOptions] = useState();
	const [selectedModule, setSelectedModule] = useState();

	const [lessonOptions, setLessonOptions] = useState();
	const [selectedLesson, setSelectedLesson] = useState();

	const [surveyDetails, setSurveyDetails] = useState();
	const [elementOptions, setElementOptions] = useState();
	const [selectedElement, setSelectedElement] = useState();

	const [baseOptions, setBaseOptions] = useState();
	const [choiceOptions, setChoiceOptions] = useState();
	const [selectComponents, setSelectComponents] = useState([]);

	const [statusVariationTab, setStatusVariationTab] = useState(0);

	const moduleToSelect = () => {
		const module = props.selectedLibrary.modules?.map((module, i) => {
			return { 
				value: i, 
				label: module.title,
				id: module.resource_id,
			};
		})

		setModuleOptions(module)
	}

	useEffect(() => {
		if(props.values?.element_id != 0){
			setSelectedModule(moduleOptions?.find(module => module.id == props.values?.text_variation_context?.module_id))
			props.setFieldValue("description", "")
		}
	}, [props.values?.element_id, moduleOptions])

	useEffect(() => {
		if(!isEmpty(props.selectedLibrary.modules)){
			moduleToSelect()
		}
	}, [props.selectedLibrary])

	const lessonToSelect = () => {
		const selected = props.selectedLibrary.modules.find(module => module.resource_id == selectedModule.id)
		const lessons = selected.lessons?.map((lesson, i) => {
			return { 
				value: i, 
				label: lesson.title,
				id: lesson.resource_id,
			};
		})

		setLessonOptions(lessons)
	}

	useEffect(() => {
		if(!isEmpty(selectedModule)){
			setLessonOptions([])
			setElementOptions([])
			setSelectedLesson({})
			lessonToSelect()
		}
	}, [selectedModule])

	useEffect(() => {
		if(props.values.element_id != 0 && !isEmpty(lessonOptions)){
			setSelectedLesson(lessonOptions?.find(lesson => lesson.id == props.values?.text_variation_context?.lesson_id))
		}
	}, [lessonOptions])

	useEffect(() => {
		if(!isEmpty(selectedElement)){
			const filteredSurveys = surveyDetails.find((survey) => survey.element_id == selectedElement.id)
			
			const options = filteredSurveys.additional_attachments?.map((choice, i) => {
				return { 
					value: i, 
					label: choice.description,
					id: choice.id,
				};
			})

			setBaseOptions(options)
			props.setFieldValue("text_variation_context.choice_number", options.length)
			
			if(props.values.is_edit == 0) {
				setChoiceOptions(options)
				setSelectComponents([
					{
						value: 0,
						label: "Main Text",
						id: 0,
						delete: false,
						description : "",
						selected : []
					},
					{
						value: 1,
						label: "Variation 1",
						id: 0,
						delete: true,
						description : "",
						selected : []
					}
				])
			}

			setShowOptions(false)
		}
		else {
			setShowOptions(true)
		}
	}, [selectedElement])

	useEffect(() => {
		if(!isEmpty(selectedLesson)){
			setElementOptions([])
			setSurveyDetails([]);
			
			let params = {
				library_party_id : props.selectedLibrary.library_id,
				module_resource_id : selectedModule.id,
				lesson_resource_id : selectedLesson.id,
			}

			if(props.values?.element_id != 0){
				setShowOptions(false)
			}

			getLessonSurveys(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					if(isEmpty(response.data.data)){
						displayToast("error", "There are no surveys on the selected lesson")
						return
					}

					const elements = response.data.data.map((element, i) => {
						return { 
							value: i, 
							label:  element.title || element.description,
							id: element.element_id,
						};
					})
					setSurveyDetails(response.data.data);
					setElementOptions(elements);

					if(props.values?.element_id != 0) {
						setShowOptions(true)
					}
				}
			});
		}
		else {
			setShowOptions(true)
		}
	}, [selectedLesson])

	useEffect(() => {
		if(props.values.element_id != 0 && !isEmpty(elementOptions)){
			setSelectedElement(elementOptions?.find(element => element.id == props.values?.text_variation_context?.element_id))
			
			let variationValue = [...props.values.text_choices]

			variationValue = variationValue?.map((choice, index)=> ({
				...choice,
				delete: index == 0 ? false : true,
				value: index,
				label: index == 0 ? "Main Text" : `Variation ${index}`,
				selected : choice.context
			}));

			setSelectComponents(variationValue)
		}
	}, [elementOptions])


	const handleAddSelectComponent = () => {
		setSelectComponents([
			...selectComponents,
			{
				value: selectComponents.length,
				label: `Variation ${selectComponents.length}`,
				delete: true,
				id: 0,
				context : "",
				selected : []
			}
		]);
	};
	
	const handleSelectChange = (selected, index) => {
		const updatedSelectComponents = [...selectComponents];
		updatedSelectComponents[index] = {
			...updatedSelectComponents[index],
			selected: selected,
		};
		
		setSelectComponents(updatedSelectComponents)

		const selectedUpdate = updatedSelectComponents.flatMap(item => item.selected?.map(choice => choice.id));
		const filteredChoices = baseOptions.filter(item => !selectedUpdate.includes(item.id));
	   
		setChoiceOptions(filteredChoices)
	};
	
	useEffect(() => {
		if(!isEmpty(selectComponents)){
			props.setFieldValue("text_choices", selectComponents)
		}
	}, [selectComponents])

	const deleteTab = (index, arr) => {
		const updatedTabs = [...arr];

		if(!isEmpty(updatedTabs[index].selected)){
			displayToast("error", "Variation has a selected choice, Please remove choices before deletion")
			return
		}

		updatedTabs.splice(index, 1);
		setSelectComponents(updatedTabs);
	}

	return (<>
		<InputLabel className="font-size-medium color-septenary">
			Show this text when:
		</InputLabel>
		{
			showOptions ? (<>
				<BaseSelect
					classNamePrefix="react-select"
					className="w-100 mb-2"
					name="variation_module"
					options={moduleOptions}
					placeholder="Select a Module"
					value={selectedModule}
					onChange={(e) => {
						setSelectedModule(e)
						props.setFieldValue("text_variation_context.module_id", e.id)
					}}
				/>
				{
					!isEmpty(selectedModule) && (
						<BaseSelect
							classNamePrefix="react-select"
							className="w-100  mb-2"
							name="variation_lesson"
							options={lessonOptions}
							placeholder="Select a Lesson"
							value={selectedLesson}
							onChange={(e) => {
								setSelectedLesson(e)
								props.setFieldValue("text_variation_context.lesson_id", e.id)
							}}
						/>
					)
				}
				{
					(!isEmpty(selectedLesson) && !isEmpty(elementOptions)) && (<>		
						<BaseSelect
							classNamePrefix="react-select"
							className="w-100 mb-2"
							options={elementOptions}
							name="variation_element"
							placeholder="Select a Element"
							value={selectedElement}
							onChange={(e) => {
								setSelectedElement(e)
								props.setFieldValue("text_variation_context.element_id", e.id)
							}}
						/>
						{(!isEmpty(selectedElement) && showOptions)&& (
							<Link onClick={() => setShowOptions(false)}>Hide</Link>
						)}
					</>)
				}
			</>) : (
				!isEmpty(selectedElement) && (
					<TextView className="font-weight-medium mb-0">
						{`${selectedModule.label} > ${selectedLesson.label} > ${selectedElement.label} > `} 
						<Link onClick={() => setShowOptions(true)}>
							Change
						</Link>
					</TextView>
				)
			)
		}
		
		{
			!isEmpty(selectedElement) && (<>
				<PageTabs
					tabs={selectComponents} 
					currentValue={statusVariationTab} 
					setCurrentValue={setStatusVariationTab}
					style={{height: "5rem"}}
					customclasses="p-0 mb-3 mt-0"
					tabclasses="d-flex align-items-end mb-3"
					deletebutton={true}
					deleteFunction={deleteTab}
				/>
				{selectComponents.map((_, index) => (
					<TabPanel
						value={statusVariationTab} 
						index={index} 
						key={index}
					>
						<BaseSelect
							key={index}
							options={choiceOptions}
							isMulti
							name={`variation_choices${index}`}
							placeholder="Select Choices"
							value={selectComponents[index].selected}
							onChange={(selected) => handleSelectChange(selected, index)}
							className="w-100 mb-2"
						/>
						<RichTextEditor
							theme="snow"
							style={{height: "100%"}}
							value={selectComponents[index].description} 
							onChange={(e)=> {
								const updatedSelectComponents = [...selectComponents];

								updatedSelectComponents[index] = {
									...updatedSelectComponents[index],
									description: e,
								};

								setSelectComponents(updatedSelectComponents)
							}} 
						/>
					</TabPanel>
				))}
				<BaseButton onClick={handleAddSelectComponent}>
					Add Variation
				</BaseButton>
			</>)
		}
	</>)
}


const mapStateToProps = (state) => {
	return {
		selectedLibrary: state.dashboard?.libraries?.selected_library,
	}
}


export default connect(mapStateToProps)(TextVariation);
