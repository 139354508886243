import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, InputLabel } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import TextView from './Typography';

const BaseCheckbox = ({...props}) => {
	let align = "";

	switch(props.align) {
		case "start":
			align = 'align-items-start justify-content-start font-size-xl';
		case "center":
		default:
			align = 'align-items-center justify-content-center';
	}

	return <>
		<FormControlLabel
			className={`me-0 p-0 ${props.customclasses}`}
			style={{
				...props.containerstyle,
				pointerEvents: props?.disabled ? 'none' : 'all',
				cursor: props?.disabled ? 'default' : 'pointer',
			}}
			onClick={props.disableTitleClick && ((e) => {e.preventDefault()})}
			control={
				<div className={`d-flex p-0 h-100 ${align}`}>
					<Checkbox
						onClick={(e) => { e.stopPropagation();}}
						{...props} 
					/>
					<TextView customclasses="ms-1 mt-1 h-100 w-100" style={{ opacity: props?.disabled ? 0.38 : 1 }}>
						{props.title}
						{props.iconedit}
					</TextView>
				</div>
			}
		/>
		{
			props.error && <FormHelperText className="color-red">{props.error}</FormHelperText>
		}
	</>
}

export default BaseCheckbox;