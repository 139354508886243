import axios from "axios";
import { objectToUrlQuery } from "../../helpers/Functions";

export function updateModule(params) {
	return axios.post(process.env.REACT_APP_API_URL + "/module/update-owner-module", params);
}

export function sortModules(params, module_id) {
	return axios.put(process.env.REACT_APP_API_URL + "/module/sort-owner-module/" + module_id, params);
}

export function deleteModule(params, module_id) {
	return axios.delete(process.env.REACT_APP_API_URL + "/module/delete-owner-module/" + module_id, {params});
}

export function getModuleActionSteps(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/module/get-module-action-steps?" + objectToUrlQuery(params));
}

export function getStudentActionResponse(params) {
	return axios.get(process.env.REACT_APP_API_URL + "/module/get-student-action-response?" + objectToUrlQuery(params));
}