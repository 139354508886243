import React, { useState, useRef, useEffect } from "react";
import TextView from "../../Typography";
import LessonAccordion from "./LessonAccordion";
import displayToast from "../../DisplayToast";
import { submitStudentElementResponse, updateElement } from "../../../../app/crud/element.crud";
import { Formik, Form } from "formik";
import { Image, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { isNotStudent } from "../../../../helpers/Utils";
import BaseButton from "../../BaseButton";
import { isEmpty, isObject } from "../../../../helpers/Functions";

const SignatureElement = ({ ...props }) => {
	const [uploadedImage, setUploadedImage] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [resettable, setResettable] = useState(true);
	const fileInputRef = useRef(null);

	const handleImageClick = () => {
		fileInputRef.current.click();
	};

	const submitStudentResponse = () => {
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: uploadedImage
		}

		setIsSubmitting(true)
		// to enable in the student view
		submitStudentElementResponse(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_element, response.data.init[0]?.["library_progress"]);
			}

			setIsSubmitting(false)
		})
	}

	useEffect(() => {
		if(!isNotStudent(props.currentCapabilities.role)) {
			const responseAttachments = props.element.attachments[0].student_attachment_response;

			if(!isEmpty(responseAttachments)) {
				setUploadedImage(process.env.REACT_APP_S3_BUCKET + responseAttachments.content)
				setResettable(false)
			}
		}
	}, [props.element])

	return (
		<LessonAccordion {...props}>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
				<input
					id="filesign"
					name="filesign"
					type="file"
					accept="image/*"
					onChange={(e) => {
						const file = e.target.files[0];
						setUploadedImage(file)
					}}
					ref={fileInputRef}
					style={{ display: 'none' }}
				/>
				<div onClick={handleImageClick}>
					{uploadedImage ? (
						<Image
							src={isObject(uploadedImage) ?  URL.createObjectURL(uploadedImage) : uploadedImage}
							style={{
								height: '150px',
								width: '175px'
							}}
							fluid
							alt="E-Signature Image"
						/>
					) : (
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							<Image
								alt="upload"
								style={{
									height: '150px',
									width: '175px',
								}}
								className="w-100 d-block rounded border p-2"
								src={
									(props.element.file_name !== null && props.element.file_name !== "" && typeof props.element.file_name !== "undefine") ?
										process.env.REACT_APP_S3_BUCKET + props.element?.directory + props.element?.file_name : 
										process.env.REACT_APP_S3_BUCKET + "/resource_uploads/signature_logo.png"
								}
							/>
							Attached
						</div>
					)}
				</div>
				{resettable && uploadedImage ? (
					<BaseButton
						variant="text"
						customclasses="mt-2 mx-auto"
						onClick={() => {
							setUploadedImage('');
						}}
					>
						Sign Again
					</BaseButton>
				) : null}
			</div>
			{
				(!isNotStudent(props?.currentCapabilities?.role) && props.element.last_element && !isEmpty(uploadedImage)) && (
					<div className="d-flex ms-2">
						<BaseButton 
							customclasses="ms-auto"
							issubmitting={ isSubmitting }
							onClick={() => {
								submitStudentResponse();
							}}
						>
							Next Element
						</BaseButton>
					</div>
				)
			}
		</LessonAccordion >
	);
};

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
	}

	return data;
}

export default connect(mapStateToProps)(SignatureElement);