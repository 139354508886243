import React, {useState} from "react";
import { connect } from "react-redux";
import DropDown from "./DropDown";

const LanguageDropdown = ({...props}) => {
	const [options] = useState(props.languages);

	return (
		<DropDown
			className={`w-100 ${props.customclasses}`}
			fontSize="primary"
			name={ props.name }
			defaultoption={{key: 0, name: "Select Language"}}
			options={options}
			onChange={(e) => {
				const selectedOption = options.find(option => option.key === e.target.value);
				props.callback(selectedOption);
			}}
			{...props}
		/>
	)
}

const mapStateToProps = (state) => {
	let data = {
		languages: state.dashboard.languages,
	}

	return data;
}

export default connect(mapStateToProps)(LanguageDropdown);