import InputLabel from '@mui/material/InputLabel';
import { FormControlLabel, Switch } from '@mui/material';
import AntSwitch from './AntSwitch';
import StyledSwitch from './StyledSwitch';

const BaseSwitch = ({...props}) => {

	return (
		<div className="d-flex align-items-center">
			<div>
				<InputLabel className={`font-size-medium mb-1 color-septenary ${props.customclasses}`}>
					{ props.title }
				</InputLabel>
			</div>
			<div
				style={{ 
					paddingBottom: "0.2rem",
					marginLeft: props.expanded == true ? "auto" : "1rem"
				}}
			>
				{
					props.styled ? (
						<StyledSwitch {...props}/>
					) : (
						<AntSwitch
							className='font-size-medium'
							{...props}
						/>
					)
				}
			</div>
		</div>
	)
}

export default BaseSwitch;