import { IconButton } from "@mui/material";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import MenuIcon from '@mui/icons-material/Menu';

const DraggableChoice = ({ ...props }) => {	
	return (
		<React.Fragment key={props.dragId}>
			<Draggable
				draggableId={props.dragId} 
				index={props.index}
				className="d-block"
			>
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						style={{
							...provided.draggableProps.style,
							top: "auto",
							boxShadow: snapshot.isDragging
								? "0 0 .4rem #666"
								: "none",
							backgroundColor: snapshot.isDragging
							? "var(--theme-primary)"
							: "white",
							color: snapshot.isDragging
							? "white"
							: "black",
							left: "auto",
						}}
					>
						<IconButton {...provided.dragHandleProps}>
							<MenuIcon />
						</IconButton>
						{props.value}
					</div>
				)}
			</Draggable>
		</React.Fragment>
	)
}

export default DraggableChoice;