import InputLabel from '@mui/material/InputLabel';
import Select from 'react-select';
import { isEmpty } from '../../helpers/Functions';
import { FormHelperText } from '@mui/material';

const BaseSelect = ({ ...props }) => {
	return (<>
		<div className='d-flex justify-content-between'>
			{
				!isEmpty(props.title) && (
					<InputLabel 
						className={`
							${
								!isEmpty(props.titleclass) ? 
								props.titleclass :
								"font-size-medium font-weight-medium mb-2 color-septenary modal-label"
							}
						`}
					>
						{props.title}
					</InputLabel>
				)
			}

			{props?.extralink && (
				props.extralink
			)}
		</div>
		
		<Select
			styles={{
				control: (styles) =>({
					...styles,
					height: !isEmpty(props.customHeight) ? props.customHeight : '3.5rem',
				}),
			}}
			theme={(theme) => ({
				...theme,
				borderRadius: 5,
				colors: {
					...theme.colors,
					primary: '#3385FD',
				},
			})}
			{ ...props }
		/>
		<FormHelperText 
			error
			style={{
				marginLeft: "1rem"
			}} 
		>
			{props.errors}
		</FormHelperText>
	</>)
}

export default BaseSelect;