import React from "react";

import LessonAccordion from "./LessonAccordion";
import TextView, { ElementTitle } from "../../Typography";
import { Link } from "react-router-dom";
import { elements } from "chart.js";
import { useLocation } from "react-router-dom";
import { isPageQuickAccess } from "../../../../helpers/Functions";
import BaseButton from "../../BaseButton";
import displayToast from "../../DisplayToast";
import { isNotStudent } from "../../../../helpers/Utils";
import { submitStudentElementResponse } from "../../../../app/crud/element.crud";
import { connect } from "react-redux";

const LinkContent = ({ ...props }) => {
	const submitStudentResponse = () => {
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
		}
		
		submitStudentElementResponse(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, response.data.init[0]?.["library_progress"]);
			}
		})
	}

	return(<>
		<TextView>
			{ props.element?.description }
		</TextView>

		{
			props.element?.additional_attachments?.map((attachment, i) => {
				return <>
					<div key={"t-" + i} className="mt-3">
						<ElementTitle display="inline-block">Link: </ElementTitle>
						<Link className="ms-2" to={ attachment?.title }>{ attachment?.title }</Link>
					</div>
		
					{
						attachment?.description && (
							<div key={"d-" + i} className="mt-2">
								<TextView display="inline-block">
									<b>Description:</b> { attachment?.description }
								</TextView>
							</div>
						)
					}
				</>
			})
		}
		{
			(!isNotStudent(props?.userCapabilities?.role) && props.element.last_element) && (
				<div className="d-flex ms-2 mt-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							submitStudentResponse();
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}
	</>)
}

const LinkElement = ({ ...props }) => {
	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);

	return (
		(!isQuickAccess) ? (
			<LessonAccordion {...props}>
				<LinkContent 
					element={props.element} 
					userCapabilities={props.currentCapabilities}
					updateProgress={props.updateProgress}
				/>
			</LessonAccordion>
		) : (
			<LinkContent 
				element={props.element} 
				userCapabilities={props.currentCapabilities}
			/>
		)
	)

	return 
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
	}

	return data;
}

export default connect(mapStateToProps)(LinkElement);