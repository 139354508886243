import { styled } from '@mui/material/styles';
import { Switch } from '@mui/material';

const StyledSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: "0.6rem",
        color:"white",
      },
      '&:before': {
        content: '"On"',
        left: 12,
      },
      '&:after': {
        content: '"Off"',
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

export default StyledSwitch;
