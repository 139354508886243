import React from "react";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import TextField from "@mui/material/TextField";
import displayToast from "./DisplayToast";
import { isEmpty } from "../../helpers/Functions";

class DatePicker extends React.Component {
	render() {
		return (
			<Datetime 
				className="content-datetime" 
				renderInput={this.renderInput} 
				{...this.props} 
			/>
		);
	}
	renderInput(props) {
		return (
			<TextField
				className="w-100"
				inputProps={{
					className: "content-textfield",
					onKeyDown: (e) => {
						const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "Delete", "Slash", "/"];
						const isNumber = /^[0-9]$/.test(e.key);

						if (!isNumber && !allowedKeys.includes(e.key)) {
							e.preventDefault();
						}
					},
					onBlur: (e) => {
						const inputValue = e.target.value;
						if(!isEmpty(inputValue)){
							const parsedDate = new Date(inputValue);
							const isValidDate =
								!isNaN(parsedDate) && parsedDate.getFullYear() <= 9999;
	
							if (!isValidDate) {
								displayToast("error", "Invalid date format!");
								const today = new Date().toLocaleDateString("en-US");
								props.onChange(today);
							}
						}
					},
				}}
				placeholder={props.placeholder}
				{...props}
			/>
		);
	}
}

export default DatePicker;
