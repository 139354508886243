import { Popover } from '@mui/material';
import TextView from './Typography';

const ContextMenu = ({ ...props}) => {
	return (
		<Popover
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			{...props}
		>
			<div 
				className="d-block"
			>
				{props.children}
			</div>
		</Popover>
	)
}

export default ContextMenu;