import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	token: "",
	api_key: "",
	company_id: "",
	user_id: "",
	user_details: "",
	user_capabilities: "",
	current_capabilities: "",
	is_super_admin: ""
}

export const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		setToken: (state, action) => {
			state.token = action.payload;
		},
		setCredentials: (state, action) => {
			state.api_key = action.payload.api_key;
			state.company_id = action.payload.company_id;
			state.user_id = action.payload.user_id;
		},
		setUserDetails: (state, action) => {
			state.user_details = action.payload;
		},
		setUserCapabilities: (state, action) => {
			state.user_capabilities = action.payload;
		},
		setCurrentCapabilities: (state, action) => {
			state.current_capabilities = action.payload;
		},
		setSuperAdmin: (state, action) => {
			state.is_super_admin = action.payload;
		},
		signOut: (state) => {
			state = initialState;
			return initialState;
		}
	}
});

export const updateCapabilitiesAsync = (updatedCapabilities) => {
	return async (dispatch) => {
		dispatch(setCurrentCapabilities(updatedCapabilities));

		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, 500);
		});
	};
};

export const {
	setToken,
	setCredentials,
	setUserDetails,
	setUserCapabilities,
	setCurrentCapabilities,
	setSuperAdmin,
	signOut
} = authSlice.actions;

export default authSlice.reducer;