import React, { useState, useEffect }  from "react";
import { isEmpty, makeCapitalize, paramFinder, transformArrayToObject, trimAIResponseToArray } from "../../../../helpers/Functions";
import { getLibraryLessonsTranscripts } from "../../../crud/element.crud";
import { connect } from "react-redux";
import displayToast from "../../../../theme/partials/DisplayToast";
import BaseSwitch from "../../../../theme/partials/BaseSwitch";
import BaseButton from "../../../../theme/partials/BaseButton";
import BaseSelect from "../../../../theme/partials/BaseSelect";

import {Collapse, IconButton, InputLabel, Popover, Tooltip} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";
import TextView from "../../../../theme/partials/Typography";
import { aiChatCompletion, aiQuiz } from "../../../crud/ai.crud";
import QuizGenerated from "./ai_results/QuizResults"

import { KeyboardArrowDown as ExpandMoreIcon, KeyboardArrowUp as ExpandLessIcon } from '@mui/icons-material';
import { AI_QUIZ_PROMPT_RETURN, QUIZ_ELEMENT } from "../../../../models/Constants";
import BaseTextField from "../../../../theme/partials/BaseTextField";
import ContextMenu from "../../../../theme/partials/ContextMenu";
import { getAIPrompts } from "../../../crud/masterdata.crud";
import LanguageDropdown from "../../../../theme/partials/LanguageDropdown";
import DropDown from "../../../../theme/partials/DropDown";
import BaseCheckbox from "../../../../theme/partials/BaseCheckbox";

const CoverageSummary = ({...props}) => {
	const [openModules, setOpenModules] = useState([]);
	const [openLessons, setOpenLessons] = useState([]);

	const handleLessonToggle = (moduleIndex, lessonIndex) => {
		const isElementDescriptionOpen = openLessons.includes(`${moduleIndex}-${lessonIndex}`);

		if (isElementDescriptionOpen) {
			setOpenLessons(openLessons.filter((i) => i !== `${moduleIndex}-${lessonIndex}`));
		} 
		else {
			setOpenLessons([...openLessons, `${moduleIndex}-${lessonIndex}`]);
		}
	};

	const handleModuleToggle = (index) => {
		const isModuleOpen = openModules.includes(index);
		
		if (isModuleOpen) {
			setOpenModules(openModules.filter((i) => i !== index));
		}
		else {
			setOpenModules([...openModules, index]);
		}
	};

	return (
		<div className="w-100 action-summary mb-3 mt-1">
			{
				props.modules?.map((module, index) => {
					let lessonIndex = 0;
					return (
						<React.Fragment key={index}>
							<div className="d-block px-2">
								<div className="d-flex align-items-center justify-content-center">
									<b>Module {index + 1} : {module.title}</b>
									<IconButton
										className="ms-auto"
										aria-label={openModules.includes(index) ? 'Collapse' : 'Expand'}
										onClick={() => handleModuleToggle(index)}
									>
										{openModules.includes(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
									</IconButton>
								</div>
								<Collapse in={openModules.includes(index)} unmountOnExit>
								{
									props.lessons.map((lesson, subIndex) => {
										(lesson.module_id === module.resource_id) && (lessonIndex = lessonIndex + 1)										
										return (lesson.module_id === module.resource_id) && (
											<React.Fragment key={subIndex}>
												<div className="d-block px-2 mb-1"> 
													<BaseCheckbox 
														onChange={(e) => {
															let editArr = [ ...props.selected ]
															let elementDetails = props.elements.find(obj => obj.lesson_id === lesson.resource_id);
															const foundElement = editArr.find(obj => obj.lesson_id === elementDetails.lesson_id);

															if(isEmpty(foundElement)) {
																editArr = [ ...editArr, elementDetails ]
															}
															else {
																editArr = editArr.filter(obj => obj.lesson_id !== foundElement.lesson_id);
															}

															props.setSelected(editArr)
														}}
														title={lessonIndex + " : " + lesson.title}
														customclasses="color-primary me-auto"
														align="start"
														disableTitleClick={true}
													/>
													<Link
														className="ms-2"
														aria-label={openLessons.includes(`${index}-${subIndex}`) ? 'Collapse' : 'Expand'}
														onClick={() => handleLessonToggle(index, subIndex)}
													>
														{openLessons.includes(`${index}-${subIndex}`) ? "Hide Transcript" : "View Transcript"}
													</Link>
												</div>
												<Collapse
													className="bg-white p-3 my-2"
													in={openLessons.includes(`${index}-${subIndex}`)}
													unmountOnExit
												>
													{props.elements.map((video, i)=>(
														(video.lesson_id == lesson.resource_id) && (
															<TextView 
																key={i}
																className="mb-3"
															>
																{video.transcript}
															</TextView>
														)
													))}
												</Collapse>
											</React.Fragment>
										)
									})
								}
								</Collapse>
							</div>
						</React.Fragment>
					)
				})
			}
		</div> 
	)
}

const AIQuizForm = ({setAI, ...props}) => {
	const [showOptions, setShowOptions] = useState(true);
	const [baseTranscript, setBaseTranscript] = useState([]);
	const [selectedTranscript, setSelectedTranscript] = useState([]);

	const [transcriptsData, setTranscriptsData] = useState([]);
	const [quizData, setQuizData] = useState([]);
	const [generating, setGenerating] = useState(false);

	useEffect(() => {
		const params = {
			library_party_id : props.selectedLibrary.library_id
		}		

		getLibraryLessonsTranscripts(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				setBaseTranscript(response.data.data)
			}
		});

		getQuizPrompts();
	}, [])

	const createPrompt = (transcript) => {
		let prompt = ""
		let paramsString = "" 
		let formatedPrompt = ""

		if (!isEmpty(controlList)) {
			paramsString = controlList.map(obj => obj?.prompt).join(' ');
			formatedPrompt = paramFinder(paramsString, transformArrayToObject(controlList, "word", "value"))

			if(!formatedPrompt){
				return
			}
		}	
		
		prompt = selectedPrompt?.prompt + "\nAbout this transcript:\n" + transcript + formatedPrompt + AI_QUIZ_PROMPT_RETURN;
		
		return prompt
	}

	useEffect(() => {
		if(transcriptsData.length == quizData.length){
			setGenerating(false);
		}
	}, [quizData])

	useEffect(() => {
		const fetchData = async () => {
			for (const data of selectedTranscript) {

				const params = {
					version: aiModel,
					prompt: createPrompt(data.transcript),
				};

				try {
					const response = await aiChatCompletion(params);
		
					if (response.data.init[0].status === 'error') {
						displayToast("error", response.data.init[0]["message"]);
					} 
					else {
						let formatResponse = trimAIResponseToArray(response.data.data[0].content)

						setQuizData(prevQuizData => [
							...prevQuizData,
							{
							  lesson_id: data.lesson_id,
							  quizzes: JSON.parse(formatResponse),
							},
						]);
					}
				} 
				catch (error) {
					console.error("Error fetching data:", error);
					displayToast("error", "An error occurred while generating data" + error.message + ": " + error.code);
					setGenerating(false);
				}
			}
			
			setGenerating(false);
		};

		if (!isEmpty(transcriptsData)) {
			fetchData(); // Call the fetchData function
		}

	}, [transcriptsData]);

	const [quizSelected, setQuizSelected] = useState([]);

	useEffect(() => {
		props.setFieldValue("ai_quiz", quizSelected)
	}, [quizSelected])

	const getQuizPrompts = () => {
		let params = {
			element_type_id: QUIZ_ELEMENT
		}

		getAIPrompts(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				const masterPromptOptions = reconstructSelect(response.data.data[0])
				setMasterPrompts(masterPromptOptions)

				if(masterPromptOptions.length == 1){
					setSelectedPrompt(masterPromptOptions[0])
				}
			}
		})
	
	}

	const reconstructSelect = (arr) => {
		if(Array.isArray(arr)) {
			const array = arr?.map((value) => {
				return { value: value.id, label: value.title, prompt: value.prompt};
			});
			return array;
		}
	}

	const [masterPrompts, setMasterPrompts] = useState([])
	const [selectedPrompt, setSelectedPrompt] = useState()
	const [controlList, setControlList] = useState([])	
	const [anchorEl, setAnchorEl] = useState(null);

	const addPersonalization = (word) => {
		const wordExist = controlList.some(obj => obj.word == word)

		if(wordExist){
			return
		}

		let list = [...controlList]
		let promptAddon = ""

		switch(word){
			case "Role" :
				promptAddon = "Tailor-fit this to the role of {{role}},\n"
				break;
			case "Industry" :
				promptAddon = "Relate this to the industry of {{industry}},\n"
				break;
			case "Team" :
				promptAddon = "Contextualize your reply with a focus on {{team}} in mind,\n"
				break;
			case "Product" :
				promptAddon = "Add some details in considerations specific to {{product}},\n"
				break;
			case "Product Description" :
				const productExist = controlList.some(obj => obj.word == "Product")

				if(!productExist){
					list.push({ word: "Product", value: "", prompt: "Add some details in considerations specific to {{product}},\n"});
				}

				promptAddon = "{{product}} is a {{product_description}},\n"
				break;
			case "Target" :
				promptAddon = "The target of this response are {{target}},\n"
				break;
			case "Language" :
				promptAddon = "Use {{language}} for the response,\n"
				break;
		}

		list.push({ word: word, value: "", prompt: promptAddon});

		setControlList(list);
	}
	
	const changeLanguageCallback = (selectedOption, control, index) => {
		let newControl = [...controlList];
		let newValue = { ...control };

		newValue.value = selectedOption.name;
		newValue.language_code = selectedOption.key;
		newControl[index] = newValue;

		setControlList(newControl);
	}

	const personalizationOptions = [
		"Role", 
		"Industry", 
		"Team", 
		"Product", 
		"Product Description", 
		"Target", 
		"Language"
	]

	const [aiModel, setAIModel] = useState("v3")
	const aiModels = [
		{ key: "v3", name: "GPT-3.5 Turbo"},
		{ key: "v4", name: "GPT-4"},
		{ key: "v4-turbo", name: "GPT-4 Turbo Preview"},   
	]

	return (<>
		<BaseSelect 
			title="Master Prompt"
			className="mb-2"
			value={selectedPrompt}
			options={masterPrompts}
			onChange={(e) => {
				setSelectedPrompt(e)
			}}
		/>

		{
			!isEmpty(selectedPrompt) && (
				<>
					<div className="d-flex">
						<Link 
							className="ms-auto me-1"
							onClick={(e) => {
								setAnchorEl(e.currentTarget);
							}}
						>
							Add Personalization
						</Link>
						
						<ContextMenu
							open={Boolean(anchorEl)}
							anchorEl={anchorEl}
							onClose={() => setAnchorEl(null)}
						>
							{
								personalizationOptions?.map((item) => (
									<TextView 
										customclasses="context-menu-item" 
										display="block"
										onClick={() => {
											addPersonalization(item)
										}}
									>
										Add {item}
									</TextView>
	
								))
							}
						</ContextMenu>
					</div>

					{controlList?.map((control, index) => {
						if (control.word == "Language") {
							return (
								<LanguageDropdown
									customclasses="mb-3"
									className="w-100"
									title="Language"
									name="language_code"
									value={!isEmpty(control?.language_code) ? control.language_code : 0}
									callback={(e) => changeLanguageCallback(e, control, index)}
								/>
							);
						} 
						else if (
							control.word == "Industry" ||
							control.word == "Team" ||
							control.word == "Role" ||
							control.word == "Product" ||
							control.word == "Target" ||
							control.word == "Product Description"
						) {
							return (
								<BaseTextField
									key={index}
									title={makeCapitalize(control.word)}
									onChange={(e) => {
										let newControl = [...controlList];
										let newValue = { ...control };
							
										newValue.value = e.target.value;
										newControl[index] = newValue;
							
										setControlList(newControl);
									}}
								/>
							);
						}
						else {
							return null;
						}
					})}
				</>
			)
		}

		<BaseSwitch 
			title={`Lesson Covered: ${showOptions ? "Show" : "Hide"}`}
			checked={showOptions}
			onChange={() => {
				setShowOptions(!showOptions)
			}}
		/>

		{
			(showOptions && !isEmpty(baseTranscript)) ? (
				<CoverageSummary
					modules={baseTranscript.modules}
					lessons={baseTranscript.lessons}
					elements={baseTranscript.elements}
					selected={selectedTranscript}
					setSelected={setSelectedTranscript}
				/>
			) : (
				(isEmpty(baseTranscript)) && (
					<div className="d-flex justify-content-center align-items-center p-4">
						<CircularProgress className="d-block "/>
					</div>
				)
			)
		}

		<DropDown
			className="w-100"
			title="Select AI Model"
			options={aiModels}
			value={aiModel}
			onChange={(e) => {
				setAIModel(e.target.value)
			}}
		/>

		<div className="mb-2">
			{
				!isEmpty(quizData) && (
					quizData?.map((lesson) => {
						let lessonData = baseTranscript.lessons.find(l => l.resource_id == lesson.lesson_id)

						return	(
							<div className="mb-3">
								<TextView className="d-block font-weight-medium mb-1 mt-2">{lessonData.title}</TextView>
								<QuizGenerated
									data={lesson.quizzes}
									quizselected={quizSelected}
									setQuizSelected={setQuizSelected}
								/>
							</div>
						)
					})
				)
			}
		</div>

		{
			!isEmpty(baseTranscript) && (
				<BaseButton
					variant="green"
					customclasses="my-2"
					issubmitting={generating}
					onClick={() => {
						let params = []

						baseTranscript.elements.map((element, i) => {
							const lessonIndex = params.findIndex((lesson) => lesson.lesson_id === element.lesson_id);
							if (lessonIndex === -1){
								//create new lesson on params
								params.push({
									lesson_id: element.lesson_id,
									transcript: element.transcript,
								});
							}
							else {
								//combine and overwrite transcript on lesson
								params[lessonIndex].transcript += `\n\n ${element.transcript}`;
							}
						})

						setTranscriptsData(params)
						setQuizData([])
						setGenerating(true);
					}}
				>
					Generate Quiz
				</BaseButton>
			)
			
		}
	</>)	
}

const mapStateToProps = (state) => {
	return {
		selectedLibrary: state.dashboard?.libraries?.selected_library,
	}
}

export default connect(mapStateToProps)(AIQuizForm);