import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";

import BaseButton from "../../BaseButton";
import { downloadsToEmail, quickDownloadsToEmail, submitStudentElementResponse } from "../../../../app/crud/element.crud";
import { isEmpty, isPageQuickAccess } from "../../../../helpers/Functions";
import displayToast from "../../DisplayToast";
import { connect } from "react-redux";
import { isNotStudent } from "../../../../helpers/Utils";

const DownloadsContent = ({ ...props }) => {
	const submitStudentResponse = () => {
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
		}

		submitStudentElementResponse(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, response.data.init[0]?.["library_progress"]);
			}
		})
	}

	return(<>
		<TextView>
			{ props.element?.description }
		</TextView>

		<div className="mt-4 mb-2">
			{
				props.element?.additional_attachments?.map((attachment, i) => {
					return (
						<Link
							key={i}
							className="d-flex mt-4"
							style={{width: "min-content"}}
							to={ process.env.REACT_APP_S3_BUCKET + props.element?.directory + attachment?.context?.file }
							target="_blank"
						>
							{ props.fileIcon(attachment?.context?.file) }
							<TextView
								customclasses="ms-3 text-nowrap align-items-center"
								fontsize="large"
								display="flex"
							>
								{ attachment?.context?.description }
							</TextView>
						</Link>
					)
				})
			}
		</div>
		<div className="d-flex justify-content-center align-items-center">
			<BaseButton 
				variant="text"
				customclasses="my-2" 
				onClick={(e) => {
					props.sendDownloadsToEmail()
				}}
			>
				Email This To Me
			</BaseButton>
		</div>
		{
			(!isNotStudent(props?.userCapabilities?.role) && props.element.last_element) && (
				<div className="d-flex ms-2 mt-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							submitStudentResponse();
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}
	</>)
}

const DownloadsElement = ({ ...props }) => {
	const fileIcon = (fileType = "doc") => {
		let src = "";
		let ext = getFileExtension(fileType);

		switch(ext) {
			case "doc":
			case "docx":
				src = "https://lernnex.s3.amazonaws.com/download_element_type/doc.png";
				break;
			case "csv":
			case "xls":
			case "xlsx":
				src = "https://lernnex.s3.amazonaws.com/download_element_type/excel.png";
				break;
			case "ppt":
			case "pptx":
				src = "https://lernnex.s3.amazonaws.com/download_element_type/ppt.png";
				break;
			case "pdf":
				src = "https://lernnex.s3.amazonaws.com/download_element_type/pdf.png";
				break;
			case "png":
			case "jpg":
			case "jpeg":
				src = "https://lernnex.s3.amazonaws.com/download_element_type/img.png";
				break;
			default:
				src = "https://lernnex-stg.s3.amazonaws.com/element_type_icon/document_icon.png";
		}

		return (
			<Image
				width="45px"
				style={{objectFit: "contain"}}
				src={ src }
				alt="document"
				className="d-inline-block"
			/>
		)
	}

	const location = useLocation();
	const isQuickAccess = isPageQuickAccess(location);

	const getFileExtension = (filename) => {
		return filename.match(/\.(.+)$/)[1];
	}

	const sendDownloadsToEmail = () => {
		let params = {
			name : props.name,
			element_id: props.element.element_id,
			email: props.email
		}

		if(props?.quickAccess){
			quickDownloadsToEmail(params).then((response) => {
				if(response.data.init[0].status == 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("success", "Email has been sent please check your email");
				}
			});
		}
		else {
			downloadsToEmail(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					displayToast("error", "Email has been sent please check your email");
				}
			});
		}
	}

	return (
		(!isQuickAccess) ? (
			<LessonAccordion {...props}>
				<DownloadsContent 
					element={props.element}
					sendDownloadsToEmail={sendDownloadsToEmail}
					fileIcon={fileIcon}
					userCapabilities={props.currentCapabilities}
					updateProgress={props.updateProgress}
				/>
			</LessonAccordion>

		) : (
			<DownloadsContent 
				element={props.element}
				sendDownloadsToEmail={sendDownloadsToEmail}
				fileIcon={fileIcon}
				userCapabilities={props.currentCapabilities}
			/>
		)

	)

	return 
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
	}

	return data;
}

export default connect(mapStateToProps)(DownloadsElement);