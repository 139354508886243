import React, { useState }  from "react";
import BaseCheckbox from "../../../../../theme/partials/BaseCheckbox";
import { isEmpty } from "../../../../../helpers/Functions";
import { Collapse } from "@mui/material";
import TextView from "../../../../../theme/partials/Typography";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const QuizGenerated = ({...props}) => {
	const [openLessons, setOpenLessons] = useState([]);

	const handleOpenLessons = (index) => {
		const isLessonOpen = openLessons.includes(index);
		
		if (isLessonOpen) {
			setOpenLessons(openLessons.filter((i) => i !== index));
		} 
		else {
			setOpenLessons([...openLessons, index]);
		}
	};

	return(<>
		{
			props.data?.map((quiz, i) => (
				<React.Fragment key={i}>
					<div className="d-block py-2 px-3 generated-quiz">
						<div className="d-flex align-items-center justify-content-center">
							<BaseCheckbox
								onChange={() => {
                                    if(props.disablechoice){
                                        return
                                    }
									
									const quizChecked = props.quizselected.find((q => q.key == quiz.key))
									let newSelected = []

									if(isEmpty(quizChecked)){
										newSelected = [
											...props.quizselected,
											quiz
										]
									}
									else {
										newSelected = props.quizselected.filter(q => q.key !== quiz.key)
									}
									
									props.setQuizSelected(newSelected)
								}}
								title={
									<div onClick={() => handleOpenLessons(i)}>
										<b className="color-secondary">
											{quiz.quiz_context.question_type == "bool" && ("True or False: ")}
											{quiz.quiz_context.question_type == "choice" && ("Multiple Choice: ")}
											{quiz.quiz_context.question_type == "multi" && ("Multiple Correct: ")}
										</b>
										{quiz.question}
									</div>
								}
								customclasses="color-primary me-auto"
								disableTitleClick={true}
							/>
						</div>

						<Collapse in={openLessons.includes(i)} unmountOnExit>
							<div className=" mt-2">
								{quiz.quiz_choices?.map((choice, a) => (
									<TextView  
										className={`d-block ms-5 ${choice.context == "correct" ? "color-green" : "color-secondary"}`} 
										key={a}
									>
										<FiberManualRecordIcon className="font-size-small"/> {choice.description} {`${choice.context == "correct" ? "(Correct Answer)" : ""}`}
									</TextView>
								))}
							</div>
						</Collapse>
					</div>
				</React.Fragment>
			))
		}
	</>)
}

export default QuizGenerated;