import TextField from '@mui/material/TextField';

const TextFieldIconButton = ({...props}) => {
	return (
		<div
 			className={`${props.customclass || "w-100"}`}
 			style={{
 				display: 'flex',
 				alignItems: 'center',
				justifyContent: 'center',
				height: '100%'
			}}
		>
			<div style={{ width: "95%", maxWidth: "95%" }}>
				<TextField {...props}/>
			</div>
			<div>{props.iconbutton}</div>
		</div>
	)
}

export default TextFieldIconButton;