import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import KeyboardDoubleArrowUpOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowUpOutlined';
import SubdirectoryArrowRightOutlinedIcon from '@mui/icons-material/SubdirectoryArrowRightOutlined';

const fontClass = "font-size-primary";
const fontSize = { fontSize: `var(--${fontClass})` };

const ReactionButton = ({ icon, text, customclasses, color = "secondary" }) => {
	return (
		<div className={`${customclasses ?? customclasses} color-${color} ${fontClass}`}>
			{ icon }
			<span className="ms-2">{ text }</span>
		</div>
	)
}

export const LikeButton = ({...props}) => {
	// TODO: update depending on activeness
	return (
		<ReactionButton icon={<ThumbUpOutlinedIcon sx={fontSize} />} {...props} />
	)
}

export const DislikeButton = ({...props}) => {
	return (
		<ReactionButton icon={<ThumbDownOutlinedIcon sx={fontSize} />} {...props} />
	)
}

export const HideReplies = ({...props}) => {
	return (
		<ReactionButton
			icon={
				<KeyboardDoubleArrowUpOutlinedIcon sx={fontSize} />
			}
			text={`Hide All Replies (${props.text})`}
			customclasses={props.customclasses}
			color="primary"
		/>
	)
}

export const ReplyButton = ({...props}) => {
	return (
		<ReactionButton
			icon={
				<SubdirectoryArrowRightOutlinedIcon sx={fontSize} />
			}
			text="Reply"
			customclasses={props.customclasses}
			color="primary"
		/>
	)
}