import React, { useEffect, useState } from 'react';

import { IconButton, Paper, CircularProgress} from '@mui/material';
import BaseTextField from '../theme/partials/BaseTextField';
import BaseButton from '../theme/partials/BaseButton';
import { ToastContainer } from "react-toastify";
import { isEmpty } from '../helpers/Functions';
import { 
	DEFAULT_OLERN_TRANSPARENT_LOGO,
	QUICK_ACCESS_BG,
	INTLIGUIDE_LOGO,
} from '../models/Constants';
import { Image } from 'react-bootstrap';
import TextView from '../theme/partials/Typography';
import { checkWebinarStudent } from './crud/library.crud';
import displayToast from '../theme/partials/DisplayToast';
import { useNavigate } from 'react-router-dom';

const EventPortal = ({...props}) => {
	const [accessLink, setAccessLink] = useState("")	
	const [submitting, setSubmitting] = useState(false)

	const navigate = useNavigate();
	const [paperStyle, setPaperStyle] = useState({})
	
	useEffect(() => {
		const checkScreenWidth = () => {
			if (window.innerWidth >= 1200) {
				setPaperStyle({ px: 3, pt: 3, pb: 3, borderRadius: 5, width : 600 });
			} 
			else {
				setPaperStyle({ px: 2, pt: 2, pb: 3, borderRadius: 5, width : 600 });
			}
		};

		checkScreenWidth();

		window.addEventListener('resize', checkScreenWidth);
		return () => window.removeEventListener('resize', checkScreenWidth);
	}, []);

	const getUserInvite = () => {
		checkWebinarStudent("", accessLink).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				let webSet = response.data.data[0]
				webSet.event_settings = JSON.parse(webSet.event_settings);
				navigate(`/events/${webSet.code}/${accessLink}`);
				console.log(`/events/${webSet.code}/${accessLink}`);
			}
		})
	}


	return (<>
		<ToastContainer />
		<div className="qa-wide-container p-3 w-100"
			style={{
				backgroundImage: "url(" + QUICK_ACCESS_BG + ")", 
				backgroundSize: 'cover', 
				backgroundPosition: 'center', 
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className='px-1 w-100 d-block'>
				<div className='d-flex w-100 justify-content-center align-items-center'>
					<Image
						className={`d-flex m-2 mb-3`}
						style={{
							backgroundColor: 'transparent',
							width: "20vh",
							objectFit: 'contain'
						}}
						src={DEFAULT_OLERN_TRANSPARENT_LOGO}
						alt="Olern Logo"
					/>
				</div>
				<center>
				<div className='px-5 w-100 d-flex justify-content-center align-items-center'>
					<Paper
						sx={paperStyle}
						className='my-5'
					>
						<div className='d-flex m-2 mb-3 justify-content-center align-items-center'>
							<Image
								className="responsive-qa-logo"
								src={INTLIGUIDE_LOGO}
								alt="Olern Logo"
							/>
						</div>
									<div className='mt-2 mb-4'>
										<TextView className="font-weight-black font-size-xl m-1">
											Lernnex Webinars
										</TextView>
										<TextView className="font-size-xm">
											Didn't get an invite email? No worries! If you have an access code, simply enter it below to join the webinar.
										</TextView>
									</div>
									<BaseTextField
										customclasses="w-100 mt-2"
										title="Enter Your Invite Code"
										placeholder="Invite Code"
										value={accessLink}
										onChange={(e) => {
											setAccessLink(e.target.value)
										}}
									/>
		
									<BaseButton
										className="font-primary font-size-medium color-white w-100 mt-2 py-2"
										customstyles={{ 
											borderRadius: '30px', 
											height: '3.5rem',
											backgroundImage: 'linear-gradient(to right, #1C272D, #33434D, #4F6370)'
										}}
										issubmitting={submitting}
										onClick={() => {
											getUserInvite()
										}}
									>
										Enter
									</BaseButton>
					</Paper>
				</div>
				</center>
				<div className='mt-4 mb-3 font-weight-black'>
					<center>
						Get FREE <a href={process.env.REACT_APP_WP_URL} target='_blank'>Trainings for SMEs!</a>
					</center>
				</div>
			</div>
		</div>
	</>);
};

export default EventPortal;