import React, { useEffect, useRef, useState } from "react";

import LessonAccordion from "./LessonAccordion";
import TextView, { ElementTitle } from "../../Typography";
import ReflectionResponse from "./ReflectionResponse";
import { Card, Image } from "react-bootstrap";
import { isNotStudent } from "../../../../helpers/Utils";
import { connect } from "react-redux";
import { getStudentAttachmentResponse, submitStudentElementResponse } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import { strDateToTimeElapse, isEmpty } from "../../../../helpers/Functions";
import { Box, CardContent } from "@mui/material";
import { DIR_USER_PROFILE } from "../../../../models/Constants";
import {Modal} from 'react-bootstrap';
import BaseButton from "../../BaseButton";
import InitialsImage from "../../InitialsImage";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ActionIconButton from "../../ActionIconButton";
import PushPinIcon from '@mui/icons-material/PushPin';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContextMenu from "../../ContextMenu";

const ConversationStarterModal = ({...props}) => {
	return (
		<Modal
			size="xl"
			show={props.showModuleModal}
			onHide={() => props.setShowModuleModal(false)}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title>Student Responses {"(" + props.studentResponses?.length + " responses)"}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ConversationStarter studentResponses={props.studentResponses ?? []} />
			</Modal.Body>
		</Modal>
	)
}

export const ConversationStarterCard = ({ 
	additionalinfo,
	firstname,
	lastname,
	imagefile,
	content,
	createdat,
	withmenu=true,
 }) => {
	const cardBtnOptionRef = useRef();
	const [openMenu, setOpenMenu] = useState(false);

	return (
		<Card className="d-inline-block h-100 w-100 conversation-starter-container position-relative">
			{withmenu && (
				<React.Fragment>
					<Box 
						className="position-absolute" 
						ref={cardBtnOptionRef}
						style={{ right: 0, top: 0 }}
					>
						<ActionIconButton 
							icon={<MoreHorizIcon />}
							customclasses="color-quinary"
							onClick={() => {
								setOpenMenu(true);
							}}
						/>
					</Box>
					<ContextMenu
						anchorEl={cardBtnOptionRef.current}
						open={openMenu}
						onClose={() => setOpenMenu(false)}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						<div style={{ padding: '16px', borderRadius: '16px' }}>
							<div className="mb-2">
								<PushPinIcon className="color-secondary" />
								<TextView fontsize="xm" fontweight="regular" customclasses="ms-2">Pin to top</TextView>
							</div>
							<div>
								<VisibilityIcon className="color-secondary" />
								<TextView fontsize="xm" fontweight="regular" customclasses="ms-2">Hide reply</TextView>
							</div>
						</div>
					</ContextMenu>
				</React.Fragment>
			)}
			<CardContent className="d-flex">
				<div className="d-flex mb-1">
					{
						(additionalinfo?.is_bot) ? (
							!isEmpty(additionalinfo.bot_image) ? (
								<Image 
									src={process.env.REACT_APP_S3_BUCKET + "/conversation_starters/images/" + additionalinfo.bot_image}
									roundedCircle
									style={{
										objectFit: "contain",
										width: "3rem",
										height: "3rem",
										marginRight: "1rem"
									}}
								/>
							) : (
								<InitialsImage 
									firstname={firstname}
									lastname={lastname}
								/>
							)
						) : (
							!isEmpty(imagefile) ? (
								<Image 
									src={process.env.REACT_APP_S3_BUCKET + DIR_USER_PROFILE + imagefile}
									roundedCircle
									style={{
										objectFit: "contain",
										width: "3rem",
										height: "3rem",
										marginRight: "1rem"
									}}
								/>
							) : (
								<InitialsImage 
									firstname={firstname}
									lastname={lastname}
								/>
							)
						)
					}
					
				</div>
				<div className="d-flex flex-column">
					<TextView fontsize="primary" fontweight="bold" style={{ lineHeight: '18px', marginBottom: '8px' }}>
						{firstname + " " + lastname?.charAt(0) + "."}
					</TextView>
					<TextView fontsize="xm" fontweight="regular">
						{content}
					</TextView>
					{createdat && (
						<TextView fontsize="small" fontweight="regular" fontcolor="secondary" style={{ lineHeight: '18px' }}>
							{strDateToTimeElapse(new Date(createdat))}
						</TextView>
					)}
				</div>
			</CardContent>
		</Card>
	);
}

const ConversationStarter = ({...props}) => {
	return (
		<div 
			className="" 
			style={{ 
				maxHeight:"30vw", 
				overflow: 'auto',
			}}
		>
			{
				props.studentResponses?.map((responses, i) => {
					let additionalInfo = JSON.parse(responses.user.additional_info)

					return (
						<ConversationStarterCard 
							key={i}
							additionalinfo={additionalInfo}
							firstname={responses?.user?.firstname}
							lastname={responses?.user?.lastname}
							imagefile={responses?.user?.image_file}
							content={responses?.content}
							createdat={responses?.created_at}
						/>	
					);
				})
			}
		</div>
	)
}

const ReflectionElement = ({ ...props }) => {
	const INITIAL_RESPONSE_COUNT = 5;

	const [studentResponses, setStudentResponses] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [responseSubmitted, setResponseSubmitted] = useState(false);
	const [answer, setAnswer] = useState();
	const [totalResponse, setTotalResponse] = useState(0);
	const [isShared, setIsShared] = useState(false);

	const getReflectionResponses = (pageSize = INITIAL_RESPONSE_COUNT) => {
		//TODO: Adjust on Student View
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			lesson_resource_id : props?.element.lesson_resource_id,
			element_id : props.element.element_id,
			is_mentor : isNotStudent(props.currentCapabilities.role) ? 1 : 0,
			page_size: pageSize,
		};

		getStudentAttachmentResponse(props?.element.attachment_id, params).then((response) => {
			if (response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			} 
			else {
				if(!isEmpty(response.data.data)) {
					setStudentResponses(response.data.data)
					setTotalResponse(response.data?.init?.[0]?.pagination?.total ?? 0);
				}
			}
		})
	}

	const [studentProgress, setStudentProgress] = useState(0)

	const submitStudentResponse = (std_res) => {
		if(isEmpty(std_res?.trim())) {
			displayToast('error', "Please type your insight before submitting.");
			return;
		}

		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
			student_response: std_res
		}

		// to enable in the student view
		if(!isNotStudent(props.currentCapabilities.role)) {
			submitStudentElementResponse(params).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					setStudentProgress(response.data.init[0]?.["library_progress"])
					setResponseSubmitted(true)
					setAnswer(std_res)
					displayToast("success", "Reflection submitted");
				}
			})
		}
		else {
			displayToast("success", "This is just a test, Answer not recorded on admin view")
		}
	}

	useEffect(() => {
		getReflectionResponses();
	}, [answer]);

	useEffect(() => {
		if(!isNotStudent(props.currentCapabilities.role)) {
			const responseAttachments = props.element.attachments[0].student_attachment_response;

			if(!isEmpty(responseAttachments)) {
				setResponseSubmitted(true)
				setAnswer(responseAttachments.content);
			}
		}

		setIsShared(props.element?.context?.is_shared == "true");
	}, [props.element]);

	return (<>
		{(isNotStudent(props?.currentCapabilities.role)) && (
			<ConversationStarterModal 
				showModuleModal={showModal}
				setShowModuleModal={setShowModal}
				studentResponses={studentResponses}
			/>
		)}
		<LessonAccordion {...props}>
			{
				props.element?.title && (
					<ElementTitle>
						{ props.element?.title }
					</ElementTitle>
				)
			}

			{
				props.element?.description && (
					<TextView >
						{ props.element?.description }
					</TextView>
				)
			}
			<Box sx={{
				marginTop: '24px'
			}}>
				{
					!isNotStudent(props?.currentCapabilities?.role) && isEmpty(answer) ? (
						<ReflectionResponse 
							hasAI={props.element.context}
							isQuestion="true"
							submitStudentResponse={submitStudentResponse}
						/>
					) : (
						<React.Fragment>
							<TextView 
								fontsize="xm" 
								fontweight="bold" 
								fontcolor="quinary"
								style={{ marginBottom: 16 }}
							>
								Answers ({ isShared ? totalResponse : 1 })
							</TextView>	
							{isShared ? (
								<ConversationStarter 
									studentResponses={studentResponses}
								/>
							) : ( // show only student answer
								<ConversationStarterCard 
									firstname={props.userDetails?.firstname}
									lastname={props.userDetails?.lastname}
									imagefile={props.userDetails?.image_file}
									content={answer}
								/>
							)}
						</React.Fragment>
					)
				}
			</Box>
			<div
			>	
				<center>
					{
						(	
							totalResponse > INITIAL_RESPONSE_COUNT && (isNotStudent(props.currentCapabilities.role) || (isShared) && responseSubmitted)
						) && (
							<BaseButton 
								variant="text"
								onClick={() => {
									getReflectionResponses(999);
									setShowModal(true);
								}}
							>
								<TextView fontcolor="primary">View All</TextView>
							</BaseButton>
						)
					}
				</center>
			</div>
			{
				(
					!isNotStudent(props?.currentCapabilities?.role) && 
					props.element.last_element && 
					responseSubmitted
				) && (
					<div className="d-flex ms-2 mt-2">
						<BaseButton 
							customclasses="ms-auto"
							onClick={() => {
								props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, studentProgress);
							}}
						>
							Next Element
						</BaseButton>
					</div>
				)
			}
		</LessonAccordion>
	</>)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
		userDetails: state.auth.user_details
	}

	return data;
}

export default connect(mapStateToProps)(ReflectionElement);