import React, { useState, useEffect, useRef, createContext, useContext } from "react";
import { connect } from "react-redux";

import TextView from "../../../theme/partials/Typography";
import VideoElement from "../../../theme/partials/libraries/lessons/VideoElement";
import TextElement from "../../../theme/partials/libraries/lessons/TextElement";
import ReflectionElement from "../../../theme/partials/libraries/lessons/ReflectionElement";
import SurveyElement from "../../../theme/partials/libraries/lessons/SurveyElement";
import LinkElement from "../../../theme/partials/libraries/lessons/LinkElement";
import ImageElement from "../../../theme/partials/libraries/lessons/ImageElement";
import AudioElement from "../../../theme/partials/libraries/lessons/AudioElement";
import DownloadsElement from "../../../theme/partials/libraries/lessons/DownloadsElement";
import displayToast from "../../../theme/partials/DisplayToast";
import PDFViewerElement from "../../../theme/partials/libraries/lessons/PDFViewerElement";
import SignatureElement from "../../../theme/partials/libraries/lessons/SignatureElement";
import BaseButton from "../../../theme/partials/BaseButton";
import ElementControllerModal, { createElementFormObject, transformValuesByElementType } from "../modals/ElementControllerModal";

import { getLessonElements, getStudentLessonActionSteps } from "../../crud/lesson.crud";
import { sortElements, deleteElement, updateElement } from "../../crud/element.crud";
import { isEmpty } from "../../../helpers/Functions";
import { setAI, setSelectedLibrary } from "../../redux/slices/dashboardSlice";

import {
	VIDEO_ELEMENT,
	REFLECTION_ELEMENT,
	SURVEY_ELEMENT,
	LINK_ELEMENT,
	IMAGE_ELEMENT,
	AUDIO_ELEMENT,
	DOWNLOAD_ELEMENT,
	TEXT_ELEMENT,
	DOCUMENT_ELEMENT,
	SIGNATURE_ELEMENT,
	QUIZ_ELEMENT,
	ACTIONS_STEP_ELEMENT,
	ACTION_SUMMARY_ELEMENT,
	WORD_CLOUD_ELEMENT,
	ROLE_STUDENT,
	RATING_ELEMENT,
	QUOTES_ELEMENT,
	CUSTOM_PLAN_ELEMENT,
} from '../../../models/Constants';
import { isNotStudent, useLoading } from "../../../helpers/Utils";
import { Skeleton } from "@mui/material";
import QuizElement from "../../../theme/partials/libraries/lessons/QuizElement";
import ActionStepsElement from "../../../theme/partials/libraries/lessons/ActionStepsElement";
import ActionSummaryElement from "../../../theme/partials/libraries/lessons/ActionSummaryElement";
import WordCloudElement from "../../../theme/partials/libraries/lessons/WordCloudElement";
import { getElementsSummary } from "../../crud/library.crud";
import RatingsElement from "../../../theme/partials/libraries/lessons/RatingsElement";
import QuotesElement from "../../../theme/partials/libraries/lessons/QuotesElement";
import CustomPlanElement from "../../../theme/partials/libraries/lessons/CustomPlanElement";
import { useLayoutContext } from "../../../theme/layout/Layout";
import { isString } from "formik";

const lessonDetailsContext = createContext();

export function useLessonDetailsContext() {
	return useContext(lessonDetailsContext);
}

const LessonDetails = ({
	setSelectedLibrary,
	setAI,
	selectedLibrary,
	...props
}) => {
	const [elements, setElements] = useState([]);
	const [showElementModal, setShowElementModal] = useState(false);
	const [editElement, setEditElement] = useState([]);
	const [update, setUpdate] = useState(false);
	const [modules, setModules] = useState([])
	const [selectedModule, setSelectedModule] = useState()
	const [selectedLesson, setSelectedLesson] = useState()
	const [selectedDataIndex, setSelectedDataIndex] = useState(0)
	const [studentLessonActionsteps, setStudentLessonActionsteps] = useState({});
	const [totalElements, setTotalElements] = useState(0);
	const abortController = useRef(null);

	const {
		isLoading,
		addLoading,
		finishLoading,
	} = useLoading();

	const { 
		uniqSelectedNavValue,
		selectedNavValue,
		selectedNavItemValue,
		setSelectedNavValue,
		setUniqSelectedNavValue,
		setSelectedNavItemValue,
	} = useLayoutContext();

	useEffect(() => {
		props.updateProgress();
	}, []);

	useEffect(() => {
		if((!selectedNavValue && !selectedNavItemValue)) {
			return;
		}

		let targetModule = modules?.find((module) => module.resource_id === selectedNavValue);
		let targetLesson = targetModule?.lessons?.find((lesson) => lesson.resource_id === selectedNavItemValue);
		
		if(targetLesson && targetModule) {
			targetLesson = {
				...targetLesson,
				lesson_id: targetLesson?.resource_id,
				module_id: targetModule?.resource_id,
			};
		}
		
		setSelectedModule(targetModule);
		setSelectedLesson(targetLesson);
	}, [selectedNavValue, selectedNavItemValue, modules]);

	useEffect(() => {
		let newModules = props.studentprogress;
		
		setModules(newModules);

		// if library doesnt have modules yet, redirect to module create page
		if((isEmpty((newModules)) || !newModules?.[0]) && !(selectedNavValue && selectedNavItemValue)) {
			setUniqSelectedNavValue(-1);
			return;
		}

		if((!selectedNavValue || !selectedNavItemValue) && isNotStudent(props?.currentCapabilities?.role)) {
			setUniqSelectedNavValue(uniqSelectedNavValue ?? newModules?.[0]?.resource_id);
		}
	}, [props.studentprogress, selectedLibrary]);

	useEffect(() => {
		if(!isEmpty(props?.library)) {
			let pagelibrary = selectedLibrary.find((lib) => lib.library_id == props?.library?.library_id)
			let dataIndex = selectedLibrary?.findIndex((lib) => lib.library_id == props.library?.library_id);

			setSelectedDataIndex(dataIndex);
		}
	}, [props.library, selectedLibrary]);

	const [moduleIndex, setModuleIndex] = useState("")

	useEffect(() => {
		if(!isEmpty(selectedLesson)) {
			setModuleIndex(modules?.findIndex(module => module.resource_id == selectedLesson?.module_id))			
		}
	}, [selectedLesson]);

	useEffect(() => {
		let lessonId = selectedLesson?.resource_id;
		
		if(!isEmpty(lessonId)) {
			addLoading();

			getStudentLessonActionSteps(lessonId).then((response) => {
				if(response.data.init[0].status === 'error') {
					displayToast("error", response.data.init[0]["message"]);
				}
				else {
					if (response.data.init[0].recno != 0) {
						setStudentLessonActionsteps(response.data?.data[0]);
					}
				}
			}).finally(() => {
				finishLoading();
			});
		}
	}, [selectedLesson, props.studentprogress]);

	const handleGetLessonElements = (callback = null) => {
		if(abortController.current) {
			abortController.current.abort();
		}

		abortController.current = new AbortController();
		const signal = abortController.current.signal;

		addLoading();

		const params = {
			is_student : (props.currentCapabilities.role === ROLE_STUDENT) ? 1 : 0
		};

		getLessonElements(selectedLesson?.resource_id, params, {signal}).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				if (response.data.init[0].recno != 0) {
					setTotalElements(response.data.data[0]?.elements.length)
					handleProgressChecking(response.data.data[0]?.elements)
				}
				else {
					// setEmptyLesson(true)
					setElements([])
				}
				
				if(callback) {
					callback(false);
				}
			}
		}).catch((error) => {
			console.error('Error fetching data:', error);
		}).finally(() => {
			finishLoading();
		});
	};

	const handleProgressChecking = (elementArr) => {
		setElements([]);

		if(!elementArr?.length > 0) {
			return;
		}

		if(props.currentCapabilities.role == ROLE_STUDENT){
			if(!isEmpty(lessonProgress)){
				let progressReport = lessonProgress?.progress_report;
				let firstElementOnly = [elementArr[0]]

				if(isEmpty(progressReport)){
					setElements(firstElementOnly);
				}
				else {
					if(!isEmpty(progressReport.element_progress.next_element)){
						let nextElementIndex = elementArr.findIndex(
							element => element.element_id == progressReport.element_progress.next_element
						)
		
						if (nextElementIndex !== -1) {
							setElements(elementArr.slice(0, nextElementIndex + 1));
						}
						else {
							setElements(firstElementOnly)
						}
					}
					else {
						setElements(elementArr)
					}
				}
			}
			else {
				//wait for lessonProgress to Load
				setElements([])
			}
		}
		else {
			setElements(elementArr);
		}
	}

	const AddElementBtn = ({ customclasses="align-self-start", keyProp}) => {
		
		return <BaseButton
			variant="green"
			customclasses={customclasses}
			onClick={() => {
				handleAddElement(keyProp);
			}}
		>
			Add Element
		</BaseButton>
	}

	const handleAddElement = (keyProp) => {
		setShowElementModal(true);
		setEditElement([]);
		setNewElementIndex(keyProp);
	}

	const createCopy = (element) => {
		let targetLibrary = props?.library;
		let libraryMentor = targetLibrary?.mentors?.find((mentor) => mentor?.is_owner == 1);
		let selectedElementType = element?.element_type_id;

		if(!isEmpty(libraryMentor?.additional_info) && isString(libraryMentor?.additional_info)) {
			libraryMentor.additional_info = JSON.parse(libraryMentor?.additional_info);
		}

		const updateData = createElementFormObject({
			newElementIndex: element?.sort + 1,
			editElement: element,
			selectedLesson,
			selectedLibrary,
			selectedElementType,
			libraryMentor,
			selectedDataIndex,
			pageLibraryData: targetLibrary,
		});

		// edit data for update mode
		updateData.is_edit = 0;
		updateData.element_id = 0;

		const resetId = (data, fieldsKey, keyName = 'id', resetValue = 0) => {
			if(!isEmpty(data?.[fieldsKey]) && Array.isArray(updateData?.[fieldsKey])) {
				updateData[fieldsKey] = updateData?.[fieldsKey]?.map((fields) => ({
					...fields,
					[keyName]: resetValue,
				}));
			}
		}

		resetId(updateData, 'files');
		resetId(updateData, 'links');
		resetId(updateData, 'choices');
		resetId(updateData, 'quiz_choices');
		resetId(updateData, 'subtitles');
		resetId(updateData, 'cloud_keywords');
		resetId(updateData, 'rating_criteria');

		transformValuesByElementType(updateData, selectedElementType);
		
		updateElement(updateData).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				let editSelectedLibrary = [...selectedLibrary]
				let libData = { ...targetLibrary }

				if(selectedElementType == QUIZ_ELEMENT) {
					libData.context = response.data.data[0]
					editSelectedLibrary[selectedDataIndex] = libData
					// setPageLibraryData(libData)
					setSelectedLibrary(editSelectedLibrary)
				}

				if(selectedElementType == TEXT_ELEMENT) {
					getElementsSummary(targetLibrary.library_code).then((response) => {
						if (response.data.init[0].status === 'error') {
							displayToast("error", response.data.init[0]["message"]);
						}
						else {
							let editSelectedLib = [...selectedLibrary]
							let lib = { 
								...targetLibrary,
								summary: response.data.data[0]
							}

							editSelectedLib[selectedDataIndex] = lib
							// setPageLibraryData(lib)
							setSelectedLibrary(editSelectedLib)
						}
					});
				}

				displayToast("success", "Element successfully copied.");
				setUpdate(true);
			}
		});
	}

	const PreviousLessonBtn = ({ customclasses="align-self-start" }) => {
		return <BaseButton
			variant="text"
			customclasses={customclasses}
			onClick={() => {
				let targetModuleId;
				let targetLessonId;

				if(selectedLesson?.sort != 0) {
					let lesson = { ...modules[selectedModule?.sort].lessons[selectedLesson?.sort - 1] };
					lesson.resource_id = lesson.resource_id;
					targetModuleId = selectedModule.resource_id;
					targetLessonId = lesson.resource_id;
				}
				else {
					let prevModuleIndex = moduleIndex - 1
					let prevModule = {...modules[prevModuleIndex]}
					let lesson = { ...modules[prevModuleIndex]?.lessons[prevModule.lessons?.length - 1] };

					lesson.resource_id = lesson.resource_id;
					targetModuleId = prevModule.resource_id;
					targetLessonId = lesson.resource_id;
				}
				
				setUniqSelectedNavValue(null);
				setSelectedNavValue(targetModuleId);
				setSelectedNavItemValue(targetLessonId);
			}}
		>
			Previous Lesson
		</BaseButton>
	}

	const NextLessonBtn = ({ customclasses="align-self-start" }) => {
		return <BaseButton
			variant="text"
			customclasses={customclasses}
			onClick={() => {
				let targetModuleId;
				let targetLessonId;

				if(selectedModule?.lessons?.length != (selectedLesson?.sort + 1)) { 
					let lesson = { ...modules[selectedModule?.sort]?.lessons[selectedLesson?.sort + 1] };
					lesson.resource_id = lesson.resource_id;
					targetModuleId = selectedModule.resource_id;
					targetLessonId = lesson.resource_id;
				}
				else {
					let nextModuleIndex = moduleIndex + 1
					let nextModule = {...modules[nextModuleIndex]}
					let lesson = { ...modules[nextModuleIndex].lessons[0]};

					lesson.resource_id = lesson.resource_id;
					targetModuleId = nextModule.resource_id;
					targetLessonId = lesson.resource_id;
				}

				setUniqSelectedNavValue(null);
				setSelectedNavValue(targetModuleId);
				setSelectedNavItemValue(targetLessonId);
			}}
		>
			Next Lesson
		</BaseButton>
	}
	
	const [sorting, setSorting] = useState(false);
	
	const handleSortElement = (index, type, element) => {
		const params = {
			element_index : index,
			library_party_id : props?.library?.party_id || props?.library?.library_id,
			module_resource_id : selectedModule?.resource_id,
			lesson_resource_id : selectedLesson?.resource_id,
			sort: (type == "up") ? (index - 1) : (index + 1)
		}

		if(params.sort < 0 || params.sort == elements.length || sorting) {
			return
		}
		
		setSorting(true)
		displayToast("info", "Elements are Sorting")
		
		sortElements(params, element.element_id).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0].message)
				setSorting(false)
			}
			else {
				displayToast("success", response.data.init[0].message);
				handleGetLessonElements(setUpdate(true));
				setSorting(false)
			}
		})
	}

	const deleteSelectedElement = (element) => {
		if(sorting) {
			displayToast("warn", "Library is sorting, please wait before deleting")
			return
		}

		const lib_type = props?.library?.library_type_id == 1 ? "trainings" :  props?.library?.library_type_id == 2 ? "manuals" : "policies"
		const params = {
			library_type: lib_type,
			library_party_id: props?.library?.party_id || props?.library?.library_id,
			module_resource_id: selectedLesson?.module_id,
			lesson_resource_id : selectedLesson?.resource_id
		}

		setSorting(true)
		deleteElement(params, element.element_id).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0].message)
				setSorting(false)
			}
			else {
				displayToast("success", response.data.init[0].message);
				setUpdate(true);
				setSorting(false);

				getElementsSummary(props?.library?.library_code).then((response) => {
					if (response.data.init[0].status === 'error') {
						displayToast("error", response.data.init[0]["message"]);
					}
					else {
						let editLibraryList = [...selectedLibrary];
						let editLibrary = editLibraryList[selectedDataIndex];

						editLibrary = {
							...editLibrary,
							summary: response.data.data[0]
						}

						editLibraryList[selectedDataIndex] = editLibrary;
						setSelectedLibrary(editLibraryList);
					}
				});
			}
		})
	}

	const [lessonProgress, setLessonProgress] = useState({})

	useEffect(() => {
		if(!isEmpty(props.studentprogress) && !isEmpty(selectedLesson)){
			let moduleLessons =  props.studentprogress?.find(module => module.resource_id == selectedModule?.resource_id)?.lessons
			let progress = moduleLessons?.find(lesson => lesson.resource_id == selectedLesson?.resource_id) 
			setLessonProgress(progress)
		}
	}, [props.studentprogress, selectedLesson])

	useEffect(() => {
		if(!isEmpty(lessonProgress)) {
			handleGetLessonElements(setUpdate(false))
		}

		return (() => {
			if(abortController.current) {
				abortController.current.abort();
			}
		})
	}, [update, lessonProgress, selectedLesson])

	const [showTestAI, setShowTestAI] = useState(false)
	const [newElementIndex, setNewElementIndex] = useState(0)
	
	return (
		(isLoading || (isEmpty(selectedLesson))) ? (
			<>
				<Skeleton className="mb-4" variant="rounded" width={"30%"} height={20} />
				<Skeleton className="mb-4" variant="rounded" width={"100%"} height={200} />
				<div className="w-100">
					<Skeleton className="mb-2" variant="rounded" width={"100%"} height={10} />
					<Skeleton variant="rounded" width={"70%"} height={10} />
				</div>
			</>
		)
		: (
			<div className="text-black">
				<ElementControllerModal
					newElementIndex={newElementIndex}
					editElement={editElement}
					selectedLesson={selectedLesson}
					setEditElement={setEditElement}
					showElementModal={showElementModal}
					setShowElementModal={setShowElementModal}
					setUpdate={setUpdate}
					testAIShowOptions={showTestAI}
					testAIShowToggle={setShowTestAI}
				/>
				
				<div className="mb-4 d-flex w-100">
					<TextView
						customclasses="flex-grow-1 align-self-center"
						display="flex"
						fontsize="l2"
					>
						<b>{selectedLesson?.title}</b>
					</TextView>
					{/* <AddElementBtn/> TODO: add scroll listener*/} 
				</div> 
				<lessonDetailsContext.Provider
					value={{
						createCopy,
					}}
				>
					{
						(!isEmpty(elements) && elements?.length > 0) ? (
							elements.map((element, i) => {
								let newElementObj = { ...element };
								newElementObj.lesson_resource_id = selectedLesson?.resource_id;
								newElementObj.module_resource_id = selectedLesson?.module_id ?? selectedModule?.resource_id;
								newElementObj.library_party_id = props?.library?.library_id;
								newElementObj.last_element = lessonProgress?.progress_report?.is_completed ? false : (i == elements.length - 1);
								newElementObj.total_elements = totalElements;

								if(!newElementObj.hasOwnProperty('additional_attachments')) {
									newElementObj.additional_attachments = newElementObj?.attachments?.[0]?.attachment_additionals;
								}

								switch (element?.element_type_id) {
									case VIDEO_ELEMENT:
										return <VideoElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
											allowquestions={props?.library?.settings?.allow_questions?.isAllowed}
										/>
									case REFLECTION_ELEMENT:
										return <ReflectionElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
										/>
									case SURVEY_ELEMENT:
										return <SurveyElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
										/>
									case LINK_ELEMENT:
										return <LinkElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
										/>
										
									case IMAGE_ELEMENT:
										return <ImageElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
										/>
									case AUDIO_ELEMENT:
										return <AudioElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
										/>
									case DOWNLOAD_ELEMENT:
										return <DownloadsElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											email={props?.userDetails?.email}
											name={props?.userDetails?.firstname}
											quickAccess={false}
											updateProgress={props.updateProgress}
										/>
									case TEXT_ELEMENT:
										return <TextElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											email={props?.userDetails?.email}
											name={props?.userDetails?.firstname}
											quickAccess={false}
											updateProgress={props.updateProgress}
										/>
									case DOCUMENT_ELEMENT:
										return <PDFViewerElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
										/>
									case SIGNATURE_ELEMENT:
										return <SignatureElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											selectedLesson={selectedLesson}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
										/>
									case QUIZ_ELEMENT:
										return <QuizElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											selectedLesson={selectedLesson}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
										/>
									case ACTIONS_STEP_ELEMENT:
										return <ActionStepsElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											selectedLesson={selectedLesson}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											studentAnswers={studentLessonActionsteps[element.element_id]?.action_steps}
											updateProgress={props.updateProgress}
										/>
									case ACTION_SUMMARY_ELEMENT:
										return <ActionSummaryElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											selectedLesson={selectedLesson}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											updateProgress={props.updateProgress}
										/>
									case WORD_CLOUD_ELEMENT:
										return <WordCloudElement 
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											selectedLesson={selectedLesson}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											updateProgress={props.updateProgress}
										/>
									case QUOTES_ELEMENT:
										return <QuotesElement 
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											selectedLesson={selectedLesson}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											updateProgress={props.updateProgress}
										/>
									case RATING_ELEMENT:
										return <RatingsElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
										/>
									case CUSTOM_PLAN_ELEMENT:
										return <CustomPlanElement
											key={i}
											element={newElementObj}
											setShowElementModal={setShowElementModal}
											setEditElement={setEditElement}
											deleteElement={deleteSelectedElement}
											sortElement={handleSortElement}
											addElement={handleAddElement}
											email={props?.userDetails?.email}
											name={props?.userDetails?.firstname}
											elementKey={i}
											elementsLength={elements.length}
											updateProgress={props.updateProgress}
										/>
								}	
								
							})
						)
						: (
							isNotStudent(props.currentCapabilities.role) ? (<>
								<TextView>
									Thanks for keeping our content top-notch!<br/>
									Kindly update the course outline and hit 'Publish' when you're ready to unveil it to our eager learners.<br/><br/>
									Need help? Check out our admin guide or reach out to support. Keep up the great work!
								</TextView>
								{
									((!isEmpty(selectedLesson)) && props.allowedit) && (
										<AddElementBtn customclasses="d-block mt-3 mx-auto mb-2" keyProp={0}/>
									)
								}
							</>)
							: (
								<TextView>
									We can't wait to show you what we've been working on!<br/><br/>
									The course outline is getting a refresh and will be available soon. In the meantime, feel free to explore our other offerings.
								</TextView>
							)
						)
					}
				</lessonDetailsContext.Provider>
				
				{
					(!isEmpty(selectedLesson) && isNotStudent(props.currentCapabilities.role)) && (
						<div className="d-flex">
							{
								(selectedLesson?.sort != 0 || moduleIndex != 0)&& (
									<PreviousLessonBtn customclasses="d-block mt-3 me-auto" />
								)
							}
							{
								(selectedModule?.lessons?.length != (selectedLesson?.sort + 1) || modules?.length != (moduleIndex + 1))&& (
									<NextLessonBtn customclasses="d-block mt-3 ms-auto"/>
								)
							}
						</div>
					)
				}
			</div>
		)
	)
}

const mapStateToProps = (state) => {
	return {
		selectedLibrary : state.dashboard?.libraries?.selected_library,
		ai: state.dashboard?.ai,
		currentCapabilities : state.auth?.current_capabilities,
		userDetails : state.auth?.user_details
	}
}

const mapDispatchToProps = {
	setSelectedLibrary,
	setAI
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonDetails);