import { useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { Stage, Layer, Image, Text, Transformer } from 'react-konva';
import useImage from 'use-image';
import { isEmpty } from '../../../../../helpers/Functions';

const KonvaStageCanvas = ({ stageRef, fontSize, profName, profImage,...props}) => {
	const [imageUrl, setImageUrl] = useState(profImage);
	const [profileImg] = useImage(imageUrl);

	useEffect(() => {
		setImageUrl(profImage)
	}, [profImage]);

	const [templateImg] = useImage(props.template?.images?.base);

	const textRef = useRef(null);
	const transformerRef = useRef(null);

	const [stageSize, setStageSize] = useState({ width: 400, height: 500 });
	const [selectedId, setSelectedId] = useState(null);

	useEffect(() => {
		if (textRef.current) {
			textRef.current.cache();
			textRef.current.getLayer().batchDraw();
		}
	}, [props.quotes, fontSize, props.fontColor, props.template]);

	useEffect(() => {
		if (stageRef.current) {
			const image = new window.Image();
			image.crossOrigin = 'anonymous'; // Ensure cross-origin images are handled
			image.src = 'your-image-url';
			image.onload = () => {
			  const konvaImage = new Konva.Image({
				image,
				x: 0,
				y: 0,
				width: image.width,
				height: image.height
			  });
			  const layer = stageRef.current.findOne('Layer');
			  layer.add(konvaImage);
			  layer.batchDraw();
			};
		}
  }, [stageRef]);

	useEffect(() => {
		if (selectedId) {
			transformerRef.current.nodes([stageRef.current.findOne(`#${selectedId}`)]);
			transformerRef.current.getLayer().batchDraw();
		}
	}, [selectedId]);

	const handleSelect = (e) => {
		const id = e.target.id();
		setSelectedId(id);
	};

	const checkDeselect = (e) => {
		const clickedOnEmpty = e.target === e.target.getStage();
		if (clickedOnEmpty) {
			setSelectedId(null);
		}
	};

	const handleDragMove = (e, text) => {
		const { x, y } = e.target.position();
	
		const updatedTemplate = {
			...props.template,
			[text]: {
				...props.template[text],
				pos_x : x,
				pos_y : y,
			}
		};
	
		props.setFieldValue("quotes_template", updatedTemplate);
	};

	return (<>
		<div className='my-2 d-flex justify-content-center'>
			{
				(!isEmpty(props.template)) ? (
					<Stage 
						ref={stageRef} 
						width={stageSize.width} 
						height={stageSize.height}
						onMouseDown={checkDeselect}
						onTouchStart={checkDeselect}
					>
						<Layer>
							{
								(props.template?.profile_image?.pos_z == "above") ? (<>
									<Image 
										image={templateImg}
										width={400}
										height={500}
									/>
									<Image 
										image={profileImg}
										height={props.template?.profile_image?.height}
										width={props.template?.profile_image?.width}
										x={props.template?.profile_image?.pos_x} 
										y={props.template?.profile_image?.pos_y}
									/>
								</>) : (<>
									<Image 
										image={profileImg}
										width={props.template?.profile_image?.width}
										height={props.template?.profile_image?.height}
										x={props.template?.profile_image?.pos_x} 
										y={props.template?.profile_image?.pos_y}
									/>
									<Image 
										image={templateImg}
										width={400}
										height={500}
									/>
								</>)
							}
							<Text
								ref={textRef}
								text={props.quotes}
								fontFamily={props.template?.quote?.family}
								fontSize={props.template?.quote?.size}
								fill={window.getComputedStyle(document.documentElement).getPropertyValue(props.template?.quote?.color).trim()}
								x={props.template?.quote?.pos_x}
								y={props.template?.quote?.pos_y}
								width={props.template?.quote?.width}
								align={props.template?.quote?.align}
								{...(props.editable && { 
									draggable: true, 
									onClick: handleSelect, 
									onTap: handleSelect, 
									onDragMove: (e) => handleDragMove(e, "quote") 
								})}
							/>
							<Text
								ref={textRef}
								text={profName}
								fontFamily={props.template?.profile_name?.family}
								fontSize={props.template?.profile_name?.size}
								fill={props.template?.profile_name?.color}
								x={props.template?.profile_name?.pos_x}
								y={props.template?.profile_name?.pos_y}
								width={props.template?.profile_name?.width}
								align={props.template?.profile_name?.align}
								{...(props.editable && { 
									draggable: true, 
									onClick: handleSelect, 
									onTap: handleSelect, 
									onDragMove: (e) => handleDragMove(e, "profile_name") 
								})}
							/>
							<Text
								ref={textRef}
								text={props.course_name}
								fontFamily={props.template?.course_name?.family}
								fontSize={props.template?.course_name?.size}
								fill={props.template?.course_name?.color}
								x={props.template?.course_name?.pos_x}
								y={props.template?.course_name?.pos_y}
								width={props.template?.course_name?.width}
								align={props.template?.course_name?.align}
								{...(props.editable && { 
									draggable: true, 
									onClick: handleSelect, 
									onTap: handleSelect, 
									onDragMove: (e) => handleDragMove(e, "course_name") 
								})}
							/>
							<Transformer
								ref={transformerRef}
								boundBoxFunc={(oldBox, newBox) => {
									if (newBox.width < 50 || newBox.height < 50) {
										return oldBox;
									}
									return newBox;
								}}
							/>
						</Layer>
					</Stage>
				) : null
			}
		</div>
	</>);
};


export default KonvaStageCanvas;