import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import LessonAccordion from "./LessonAccordion";
import TextView from "../../Typography";
import { useLocation } from "react-router-dom";
import { isPageQuickAccess } from "../../../../helpers/Functions";
import { connect } from "react-redux";
import { submitStudentElementResponse } from "../../../../app/crud/element.crud";
import displayToast from "../../DisplayToast";
import { isNotStudent } from "../../../../helpers/Utils";
import BaseButton from "../../BaseButton";

const PDFContent = ({ ...props }) => {
	
	const submitStudentResponse = () => {
		const params = {
			library_party_id: props.element.library_party_id,
			module_resource_id: props.element.module_resource_id,
			element_id: props.element.element_id,
			lesson_resource_id: props.element.lesson_resource_id,
			element_attachment_id: props?.element.attachment_id,
		}

		submitStudentElementResponse(params).then((response) => {
			if(response.data.init[0].status === 'error') {
				displayToast("error", response.data.init[0]["message"]);
			}
			else {
				props.updateProgress(undefined, (props.element.sort + 1) == props.element.total_elements, response.data.init[0]?.["library_progress"]);
			}
		})
	}

	return (<>
		<TextView>
			{ props.element?.description }
		</TextView>

		<div className="d-flex justify-content-center mt-4">
			<Link
				to={ process.env.REACT_APP_S3_BUCKET + props.element?.directory + props.element?.file_name }
				target="_blank"
				className="d-flex"
				style={{width: "min-content"}}
			>
				<Image
					width="60px"
					src={ process.env.REACT_APP_S3_BUCKET + "/pdf.png" }
					alt="document"
					className="d-inline-block"
					style={{objectFit: "contain"}}
				/>
				<TextView
					customclasses="ms-3 text-nowrap align-items-center"
					fontsize="small"
					display="flex"
				>
					{ props.element?.context?.file_name }
				</TextView>
			</Link>
		</div>
		{
			(!isNotStudent(props?.userCapabilities?.role) && props.element.last_element) && (
				<div className="d-flex ms-2 mt-2">
					<BaseButton 
						customclasses="ms-auto"
						onClick={() => {
							submitStudentResponse();
						}}
					>
						Next Element
					</BaseButton>
				</div>
			)
		}
	</>)
}

const PDFViewerElement = ({ ...props }) => {
	const location = useLocation();
  	const isQuickAccess = isPageQuickAccess(location);

	// TODO: upload pdf files only
	return (
		(!isQuickAccess) ? (
			<LessonAccordion {...props}>
				<PDFContent 
					element={props.element}	
					userCapabilities={props.currentCapabilities}
					updateProgress={props.updateProgress}
				/>
			</LessonAccordion>
		) : (
			<PDFContent 
				element={props.element}
				userCapabilities={props.currentCapabilities}
			/>
		)
	)
}

const mapStateToProps = (state) => {
	let data = {
		currentCapabilities: state.auth.current_capabilities,
	}

	return data;
}

export default connect(mapStateToProps)(PDFViewerElement);