import React, { useEffect, useState } from 'react'
import { getRandomColor, isEmpty } from '../../helpers/Functions'

const InitialsImage = ({ ...props }) => {
	const [initials, setInitials] = useState()
	const [style, setStyle] = useState({})

	useEffect(() => {
		let ini = ""

		if(!isEmpty(props?.firstname)) {
			ini = props?.firstname.slice(0, 1) + props?.lastname?.slice(0, 1);
		}

		setInitials(ini)
		
		const { bgColor, fontColor } = getRandomLightOrDarkColor();

		if(!isEmpty(props?.style)) {
			let initialStyle = {...props.style}

			initialStyle.backgroundColor = !isEmpty(initialStyle?.backgroundColor) ? initialStyle?.backgroundColor : bgColor
			initialStyle.color = !isEmpty(initialStyle?.color) ? initialStyle?.color : fontColor

			setStyle(props.style)
		}
		else {
			setStyle({
				objectFit: "contain",
				width: "3rem",
				height: "3rem",
				marginRight: "1rem",
				backgroundColor: bgColor,
				borderRadius: "50%",
				display: "flex",
				alignItems: "center",
				justifyContent: "center", 
				color: fontColor,
				fontSize: "1.5rem"
			})
		}
	}, [])

	function getRandomLightOrDarkColor() {
		const randomColor = getRandomColor(Math.floor(Math.random() * 128));
		const isLightColor = parseInt(randomColor.substring(1), 16) > 0xffffff / 2;

		return {
			bgColor: props.mono ? '#F15B29' : randomColor,
			fontColor: props.mono ? '#ffffff' : (isLightColor ? '#ffffff' : '#000000')
		};
	}

	return (
		<div
			style={style}
		>
			{initials}
		</div>
	)
}

export default InitialsImage;